import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Status from '../UI/Status';

const OrderInfo = props => {

  const { title, value, emptySpace } = props;

  return (
    <div className='bm-order-item__info'>
      <span className='bm-order-item__info__title'>{ title }{emptySpace}</span>
      <span className='bm-order-item__info__value'>{ value }</span>
    </div>
  )
}

const OrderHistoryItem = props => {

  const { order } = props;

  return (
    <Grid container spacing={3} className='bm-order-item'>
      <Grid item xs={6} className='bm-order-item__patient-details'>
        <Grid container spacing={3}>
          <Grid item>
            <div className='bm-order-item__age-gender-box'>
              <span>{ order.gender }</span>
              <span>{ order.age } y</span>
            </div>
          </Grid>
          <Grid item xs={9} className='bm-order-item__details'>
            <span className='bm-order-item__details__patient-name'>{ order.name }</span>
            <Status 
              status={ order.status }
            />
            <div className='bm-order-item__divider'></div>
            <OrderInfo 
              title={'Request ID:'}
              value={ order.orderId }
              emptySpace={ <span>&emsp;</span> }
            />
            <OrderInfo 
              title={'Order Total:'}
              value={ order.orderTotal }
              emptySpace={ <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} className='bm-order-item__action'>
        <Button
          variant='contained'
          className='mp-button-sm list-action-button'
          // onClick={ updateStatus }
          // startIcon={ isUpdatingStatus ? <CircularProgress size={16} color={'inherit'}  /> : <VideoCall /> }
        >
          View Prescription
        </Button>        
      </Grid>
    </Grid>
  )
}

export default OrderHistoryItem;