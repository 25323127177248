import React from 'react';
import Grid from '@material-ui/core/Grid';

import ICON from '../../assets/images/mediversal-icon.png';
import GOOGLE_PLAY from '../../assets/images/google-play-badge.svg';
import APP_STORE from '../../assets/images/app-store-badge.svg';

import DownloadAppLayout from '../../hocs/DownloadAppLayout';

const SmallScreen = props => {

  return (
    <DownloadAppLayout>
      <div className='download-app'>

        <img src={ICON} className='download-app__icon' />

        <span className='download-app__name'>Mediversal Partner</span>
        <span className='download-app__divider'>By</span>
        <span className='download-app__company'>Mediversal</span>

        <span className='download-app__message'>Upgrade to smarter experience</span>

        <div className='download-app__images'>
          <img src={GOOGLE_PLAY} />
          <img src={APP_STORE} />
        </div>
      </div>
    </DownloadAppLayout>
  )
}

export default SmallScreen;