import React from 'react';
import { connect } from 'react-redux';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Modal from '../../components/UI/Modal';
import TextInput from '../../components/UI/TextInput';

import { updateInventory } from '../../store/actions/inventory';
import { inputValidationHelper } from '../../helpers/validations';

class EditInventory extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      inputConfig: {
        name: {
          readOnly: true,
          fullWidth: true,
          label: 'Name',
          placeholder: 'Name',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('name', event.target.value)
        },
        sku: {
          readOnly: true,
          fullWidth: true,
          label: 'SKU',
          placeholder: 'SKU',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('sku', event.target.value)
        },
        quantityUploaded: {
          readOnly: true,
          fullWidth: true,
          label: 'Uploaded quantity',
          placeholder: 'Uploaded quantity',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('quantityUploaded', event.target.value)
        },
        availableInventory: {
          readOnly: true,
          fullWidth: true,
          label: 'Available quantity',
          placeholder: 'Available quantity',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('availableInventory', event.target.value)
        },
        quantityConfirmed: {
          required: true,
          fullWidth: true,
          label: 'Uploaded quantity',
          placeholder: 'Uploaded quantity',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('quantityConfirmed', event.target.value)
        },
        eodInventory: {
          required: true,
          fullWidth: true,
          label: 'EOD Inventory',
          placeholder: 'EOD Inventory',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('eodInventory', event.target.value)
        }
      },
      offerId: '',
      isFormValid: true
    }
  }

  componentDidUpdate (prevProps) {

    if(prevProps.isEdit !== this.props.isEdit) {
      this.manageState();
    }

    if(prevProps.inventory.isUpdatingInventory && !this.props.inventory.isUpdatingInventory && this.props.inventory.inventoryUpdated) {
      this.props.close();
    }
  }

  manageState = () => {
    const inputConfig = _cloneDeep(this.state.inputConfig);
    const { product } = this.props;

    inputConfig['name']['value'] = _get(product, 'name', '');
    inputConfig['sku']['value'] = _get(product, 'sku', '');
    inputConfig['quantityUploaded']['value'] = _get(product, 'qty_uploaded', 0);
    inputConfig['availableInventory']['value'] = _get(product, 'inventory_qty', 0);
    inputConfig['quantityConfirmed']['value'] = _get(product, 'qty_confirmed', 0);
    inputConfig['eodInventory']['value'] = _get(product, 'eod_inventory', 0);

    this.setState({
      inputConfig,
      offerId: _get(product, 'offer_id', '')
    });
  }

  handleInputChange = (input, value) => {

    const inputConfig = _cloneDeep(this.state.inputConfig);
    inputConfig[input]['value'] = value;

    this.setState({
      inputConfig
    });
  }

  validateUserInputs = (input, inputConfig, isFormValid) => {
    
    const validationResult = inputValidationHelper(input, input === 'state' ? inputConfig[input]['name'] : inputConfig[input]['value']);
    
    if(!validationResult.isValid)
    {
      inputConfig[input]['hasError'] = true;
      inputConfig[input]['errorMessage'] = validationResult.reason;
      isFormValid = false;
    }
    else {
      inputConfig[input]['hasError'] = false;
      inputConfig[input]['errorMessage'] = ''
    }
    return isFormValid;
  }

  handleUpdateInventory = () => {

    let isFormValid = true;
    const inputConfig = _cloneDeep(this.state.inputConfig);
    const { updateInventory, inventory } = this.props;
    const { offerId } = this.state;

    if(inventory.isUpdatingInventory) return;

    isFormValid = this.validateUserInputs('quantityConfirmed', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('eodInventory', inputConfig, isFormValid);

    this.setState({
      inputConfig
    });

    if(isFormValid) 
      updateInventory({
        offerId: offerId,
        quantityConfirmed: inputConfig['quantityConfirmed']['value'],
        eodInventory: inputConfig['eodInventory']['value'],
      });
  }

  render () {

    const { inputConfig } = this.state;
    const { isEdit, close } = this.props;

    const footer = !isEdit ? null : (
      <div>
        <Button 
          className='availability-item-btn'
          onClick={ close }
        >
          <span>Cancel</span>
        </Button>
        <Button 
          className='availability-item-btn availability-item-btn-active'
          onClick={ this.handleUpdateInventory }
        >
          <span>{ this.props.inventory.isUpdatingInventory ? 'Saving...' : 'Save' }</span>
        </Button>
      </div>
    );

    const content = !isEdit ? null : (
      <Grid container spacing={3} className='edit-inventory'>
        <Grid item xs={6}>
          <TextInput 
            {...inputConfig.name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput 
            {...inputConfig.sku}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput 
            {...inputConfig.quantityUploaded}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput 
            {...inputConfig.availableInventory}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput 
            {...inputConfig.quantityConfirmed}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput 
            {...inputConfig.eodInventory}
          />
        </Grid>
      </Grid>
    );

    return (
      <Modal
        isOpen={isEdit}
        close={close}
        title={'Edit Inventory'}
        content={content}
        footer={footer}
      />
    );
  }
}

const mapStateToProps = state => ({
  inventory: state.inventory
});

const mapDispatchToProps = dispatch => ({
  updateInventory: inventory => dispatch(updateInventory(inventory))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditInventory);