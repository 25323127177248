import { get, isEmpty } from 'lodash';

import API_INSTANCE from '../../helpers/apiInstance';
import * as constants from '../constants/auth';
import * as locationConstants from '../constants/locations';
import {
  DEFAULT_ERROR_MESSAGE
} from '../../constants/messages';
import { 
  formatVendorData, 
  formatEnabledCategories 
} from '../../helpers/actionHelpers';
import {
  showAlert
} from './alerts';
import {
  storeData,
  getData
} from '../../helpers/storageHelper';
import { 
  changeSelectedLocation
} from './locations';

const dispatchAutoLoginFailed = hasFailed => ({
  type: constants.ACTION_AUTO_LOGIN_FAILED,
  isAutoLoginFailed: hasFailed
});

const dispatchAutoLogin = isAutoLoggingIn => ({
  type: constants.ACTION_AUTO_LOGIN,
  isAutoLoggingIn: isAutoLoggingIn
});


const changeSelectedCategory = selectedCategory => ({
  type: constants.CHANGE_SELECTED_CATEGORY,
  selectedCategory: selectedCategory
});

const dispatchLogin = isLoggingIn => ({
  type: constants.ACTION_LOGIN,
  isLoggingIn: isLoggingIn
});

const dispatchUserData = data => ({
  type: constants.ACTION_USER_DATA,
  data: data
});

const dispatchLocations = locationsList => ({
  type: locationConstants.ACTION_LOCATION_DATA,
  locationsList: locationsList
});

const dispatchLogout = () => ({
  type: constants.ACTION_LOGOUT
});

const checkLoggedIn = () => async (dispatch) => {
  const vendorId = await getData('vendorId');
  if(isEmpty(vendorId)) return true;
  dispatch(autoLogin(vendorId));
}

const login = (username, password) => async (dispatch) => {
  dispatch(dispatchLogin(true));
  try {
    const options = {
      url: constants.LOGIN_URL,
      method: constants.LOGIN_METHOD,
      data: {
        user_name: username,
        password: password
      }
    }
    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {

      const vendorData = formatVendorData(get(response, ['data', 0, 'vendor'], {}));

      dispatch(dispatchLocations(get(response, ['data', 0, 'locations'], [])));
      dispatch(changeSelectedLocation(get(response, ['data', 0, 'locations', 0, 'location_id'], '')));
      dispatch(changeSelectedCategory(get(response, ['data', 0, 'vendor', 'vendor_type', 0, 'vendor_type_label'], '')));
      dispatch(dispatchUserData({
        vendorData: vendorData,
        enabledCategories: formatEnabledCategories(get(response, ['data', 0, 'vendor', 'vendor_type'], []))
      }));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch (error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchLogin(false));
}

const logout = () => async (dispatch) => {
  try{
    dispatch(dispatchLogout());
  }
  catch(error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
};

const autoLogin = vendorId => async (dispatch) => {
  try {
    dispatch(dispatchAutoLogin(true));
    const options = {
      url: `${constants.AUTO_LOGIN_URL}/${vendorId}`,
      method: constants.AUTO_LOGIN_METHOD,
    }
    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {

      const vendorData = formatVendorData(get(response, ['data', 0, 'vendor'], {}));

      dispatch(dispatchUserData({
        vendorData: vendorData,
        enabledCategories: formatEnabledCategories(get(response, ['data', 0, 'vendor', 'vendor_type'], [])),
      }));
      dispatch(dispatchLocations(get(response, ['data', 0, 'locations'], [])));
      dispatch(changeSelectedCategory(get(response, ['data', 0, 'vendor', 'vendor_type', 0, 'vendor_type_label'], '')));
    }
    else {
      dispatch(dispatchAutoLoginFailed(true));
    }
  }
  catch (error) {
    dispatch(dispatchAutoLoginFailed(true));
  }
  dispatch(dispatchAutoLogin(false));
}

export {
  changeSelectedCategory,
  checkLoggedIn,
  login,
  logout
}