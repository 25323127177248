import React from 'react';

const Status = props => {

  const { status } = props;

  return (
    <div className='mp-status'>
      <div className={`mp-status__icon mp-status__icon-${status.toLowerCase()}`}></div>
      <span className={`mp-status__status mp-status__status-${status.toLowerCase()}`}>
        { status }
      </span>
    </div>
  );
}

export default Status;