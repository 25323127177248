import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import ChevronRight from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from '../../hocs/DefaultLayout';
import Loader from '../../components/UI/Loader';
import Empty from '../../components/UI/Empty';
import InventoryFilters from '../../components/Filters/InventoryFilters';
import Table from '../../components/UI/Table';
import EditInventory from './EditInventory';

import { fetchInventory } from '../../store/actions/inventory';
import { NO_INVENTORY_DETAILS, NO_PRODUCTS_FOUND_IN_INVENTORY } from '../../constants/messages';

class Inventory extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      searchText: '',
      isEdit: false,
      editingProduct: {}
    }
    this.columns = [
      { id: 'name', label: 'Name', minWidth: 200 },
      { id: 'sku', label: 'SKU', minWidth: 100 },
      { id: 'qty_uploaded', label: 'Uploaded Quantity', align: 'right' },
      { id: 'qty_confirmed', label: 'Confirmed Quantity', align: 'right' },
      { id: 'eod_inventory', label: 'EOD Quantity', align: 'right' },
      { id: 'inventory_qty', label: 'Available Quantity', align: 'right' },
      { id: 'action', label: 'Action', align: 'center', action: (data) => this._renderAction(data) }
    ]
  }

  componentDidMount () {
    if(!_isEmpty(this.props.locations.selectedLocation)) {
      this.manageState();
    }
  }

  componentDidUpdate(prevProps) {

    if(prevProps.locations.selectedLocation !== this.props.locations.selectedLocation) {
      this.manageState();
    }
  }

  manageState = () => {
    const { fetchInventory, locations } = this.props;

     fetchInventory(locations.selectedLocation);
  }

  handleInputChange = (value) => {
    
    this.setState({
      searchText: value
    });
  }

  openEditModal = data => {
    this.setState({
      isEdit: true,
      editingProduct: data
    });
  }

  closeEditModal = () => {
    this.setState({
      isEdit: false,
      editingProduct: {}
    });
  }

  _renderAction = data => {
    return (
      <Button
        className='edit-inventory-btn'
        onClick={ () => this.openEditModal(data) }
      >
        EDIT
      </Button>
    )
  }

  _renderInventory = () => {
    
    const { inventory } = this.props;
    const { searchText } = this.state;
    
    let markup = null;
    let data = inventory.inventory;

    // data = [
    //   {
    //     offer_id: '123',
    //     name: 'First Product',
    //     sku: 'SKU10001',
    //     qty_uploaded: 100,
    //     qty_confirmed: 10,
    //     eod_inventory: 50,
    //     inventory_qty: 50
    //   },
    //   {
    //     offer_id: '1234',
    //     name: 'Second Product',
    //     sku: 'SKU10002',
    //     qty_uploaded: 100,
    //     qty_confirmed: 10,
    //     eod_inventory: 50,
    //     inventory_qty: 50,
    //   }
    // ]

    const lowerTextSearch = searchText.toLocaleLowerCase();
    if(!_isEmpty(searchText)) data = data.filter(product => product.name.toLowerCase().includes(lowerTextSearch) || product.sku.toLowerCase().includes(lowerTextSearch))

    if(inventory.isFetchingInventory) {
      return <Loader />
    }
    else if(_isEmpty(data)) {
      return (
        <Empty 
          message={ _isEmpty(searchText) ? NO_INVENTORY_DETAILS : NO_PRODUCTS_FOUND_IN_INVENTORY }
        />
      )
    }
    else {
      markup = (
        <Table 
          columns={this.columns}
          rows={data}
        />
      )
    }

    return markup;
  }

  render() {

    const { searchText, isEdit, editingProduct } = this.state;

    return (
      <Layout>
        {
          <InventoryFilters 
            searchText={ searchText }
            handleChange={ this.handleInputChange }
          />
        }
        {
          this._renderInventory()
        }
        <EditInventory 
          isEdit={isEdit}
          product={editingProduct}
          close={this.closeEditModal}
        />
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  inventory: state.inventory,
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchInventory: locationId => dispatch(fetchInventory(locationId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);