import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => {

  return (
    <div className='mp-loader'>
      <div className='mp-loader__loader'>
        <CircularProgress
          fontSize={40} 
          color={'inherit'}
        />
      </div>
    </div>
  )
}

export default Loader;