import React from 'react';
import Grid from '@material-ui/core/Grid';

const DateDivider = props => {

  const { date } = props;

  return (
    <Grid container spacing={3} className='date-divider'>
      <Grid item>
        <span>{ date }</span>
      </Grid>
      <Grid item xs>
        <div className='date-divider__horizontal-line'>
          &nbsp;
        </div>
      </Grid>
    </Grid>
  )
}

export default DateDivider;