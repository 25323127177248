import { cloneDeep } from 'lodash';

import * as constants from '../constants/inventory';
import * as loginConstants from '../constants/auth';

const initialState = {
  isFetchingInventory: false,
  isUpdatingInventory: false,
  inventoryUpdated: false,
  inventory: []
}

const inventoryReducer = (state = cloneDeep(initialState), action) => {

  switch(action.type) {
    
    case constants.ACTION_FETCH_INVENTORY:
      return {
        ...cloneDeep(state),
        isFetchingInventory: action.isFetchingInventory
      };

    case constants.ACTION_INVENTORY:
      return {
        ...cloneDeep(state),
        inventory: action.inventory
      };

    case constants.ACTION_UPDATE_INVENTORY:
      return {
        ...cloneDeep(state),
        isUpdatingInventory: action.isUpdatingInventory
      };

    case constants.ACTION_UPDATE_INVENTORY_SUCCESS:
      return {
        ...cloneDeep(state),
        inventoryUpdated: action.inventoryUpdated
      };

    case loginConstants.ACTION_LOGOUT:
      return cloneDeep(initialState);

    default:
      return state;
  }
}

export default inventoryReducer;