import React from 'react';
import _get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import { TERTIARY } from '../../assets/colors/colors';

const Info = props => {

  const { Icon, value } = props;

  return (
    <div className='location-item__info'>
      <Icon style={{ fontSize: 20, color: TERTIARY }} />
      <span className='location-item__info__value'>{ value }</span>
    </div>
  )
}

const ClinicItem = props => {

  const { location, isSelected, selectClinic } = props;

  const address = `${_get(location, ['address', 'street', 0], '')}, ${_get(location, ['address', 'street', 1], '')}, ${_get(location, ['address', 'loc_city'], '')}, ${_get(location, ['address', 'loc_state', 'name'], '')} - ${_get(location, ['address', 'loc_postcode'], '')}`;

  return (
    <Grid item xs={4}>
      <div 
        className={`location-item ${ isSelected ? 'location-item-active' : '' }`}
        onClick={selectClinic}
      >
        <span className='location-item__name'>{ location.loc_name }</span>
        <span className='location-item__location-id'>Clinic ID: <strong>{ location.location_id }</strong></span>

        <Info 
          Icon={LocationOnIcon}
          value={address}
        />
        <Info 
          Icon={PhoneIcon}
          value={location.loc_phone ? location.loc_phone : '-'}
        />
        <Info 
          Icon={EmailIcon}
          value={location.loc_email ? location.loc_email : '-'}
        />
      </div>
    </Grid>
  )
}

export default ClinicItem;