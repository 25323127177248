import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import Layout from '../../hocs/DefaultLayout';
import Loader from '../../components/UI/Loader';
import Empty from '../../components/UI/Empty';
import Filters from '../../components/Filters/OrderFilters';
import LabTestGroups from '../../components/LabTests/LabTestGroups';
import LabTestItem from '../../components/LabTests/LabTestItem';

import { fetchAppointments, updateAppointmentStatus } from '../../store/actions/orders';
import { NO_CONFIRMED_LAB_TESTS, NO_PENDING_LAB_TESTS } from '../../constants/messages';

class Appointments extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      isFetchingAppointments: true,
      updatingAppointmentId: '',
      status: 'CONFIRMED'
    }
  }

  componentDidMount() {

    if(!_isEmpty(this.props.locations.selectedLocation)) {
      this.manageState();
    }
  }

  componentDidUpdate (prevProps) {

    if(prevProps.locations.selectedLocation !== this.props.locations.selectedLocation) {
      this.manageState();
    }
  }

  manageState = () => {
    const { fetchAppointments } = this.props;
    fetchAppointments();
    this.setState({
      isFetchingAppointments: false
    });
  }

  handleStatusChange = status => {
    this.setState({
      status: status
    });
  }

  handleUpdateStatus = (appointmentId, itemIds, date) => {
    const { updateAppointmentStatus, orders } = this.props;
    if(orders.isUpdatingStatus) return;

    this.setState({
      updatingAppointmentId: appointmentId
    }, () => updateAppointmentStatus(appointmentId, itemIds, date));
  }

  _renderLabTests = () => {
    const { orders } = this.props;
    const { isFetchingAppointments, status, updatingAppointmentId } = this.state;
    const data = _get(orders, ['appointments', status], []);
    let markup = null;

    if(orders.isFetchingAppointments || isFetchingAppointments) {
      return <Loader />
    }
    else if(_isEmpty(data)) {
      return (
        <Empty 
          message={ status === 'CONFIRMED' ? NO_CONFIRMED_LAB_TESTS : NO_PENDING_LAB_TESTS}
        />
      )
    }
    else {
      markup = (
        _map(data, (orderGroup, date) => (
          <LabTestGroups 
            key={date}
            date={date}
            orders={orderGroup}
            updateStatus={this.handleUpdateStatus}
            Component={LabTestItem}
            updatingAppointmentId={updatingAppointmentId}
            isUpdatingStatus={orders.isUpdatingStatus}
          />
        ))
      )
    }
    return (
      <section className='lt-orders-container'>
        {
          markup
        }
      </section>
    );
  }

  render() {

    const { status } = this.state;

    return (
      <Layout>
        <Filters 
          component={'LabTests'}
          locationLabel={'Labs'}
          activeStatus={status}
          handleStatusChange={ this.handleStatusChange }
        />
        {
          this._renderLabTests()
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  isAutoLoggingIn: state.auth.isAutoLoggingIn,
  orders: state.orders,
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchAppointments: () => dispatch(fetchAppointments()),
  updateAppointmentStatus: (appointmentId, itemId, date) => dispatch(updateAppointmentStatus(appointmentId, itemId, date))
});

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);