import axios from 'axios';

import config from '../config/config';

const instance = axios.create({
  baseURL: config.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${config.bearerToken}`
  },
  validateStatus: function (status) {
    return status >= 200 && status <= 404; // default
  }
});

instance.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});

instance.interceptors.response.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});

export default instance;