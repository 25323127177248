import * as constants from '../constants/alerts';

const showAlert = (message, messageType = 'info') => dispatch => {
  
  dispatch ({
    type: constants.SHOW_ALERT,
    message: message,
    messageType: messageType
  });
}

const hideAlert = () => dispatch => {
  dispatch({
    type: constants.HIDE_ALERT,
  })
}

export {
  showAlert,
  hideAlert
}
