import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';

import SelectInput from '../UI/SelectInput';

import { changeSelectedLocation } from '../../store/actions/locations';

const status = [
  { id: 'CONFIRMED', name: 'Confirmed' }, 
  { id: 'PENDING', name: 'Pending' }
];

const Filters = props => {

  const { activeStatus, handleStatusChange, component, locationLabel } = props;
  const dispatch = useDispatch();
  const locations = useSelector(state => state.locations);

  const locationOptions = locations.locationsList.map(location => ({ id: location.location_id, name: location.loc_name }))

  const locationInput = _isEmpty(locationOptions) ? null : (
    <Grid item xs={2}>
      <SelectInput 
        fullWidth={true}
        label={locationLabel}
        showChooseLabel={false}
        value={locations.selectedLocation}
        options={locationOptions}
        handleChange={ (event) => dispatch(changeSelectedLocation(event.target.value)) }
      />
    </Grid>
  );

  const statusInput = (
    <Grid item xs={2}>
      <SelectInput 
        fullWidth={true}
        label={'Status'}
        showChooseLabel={false}
        value={activeStatus}
        options={status}
        handleChange={ (event) => handleStatusChange(event.target.value) }
      />
    </Grid>
  );

  switch (component) {

    case 'Appointments':
    case 'LabTests':
    case 'Orders':
      return (
        <Grid container spacing={5} className='order-filter-container'>
          <Grid item xs={8}>
          </Grid>
          {
            statusInput
          }
          {
            locationInput
          }
        </Grid>
      );

    case 'AppointementHistory':
    case 'LabTestHistory':
    case 'OrderHistory':
      return (
        <Grid container spacing={5} className='order-filter-container'>
          <Grid item xs={10}>
          </Grid>
          {
            locationInput
          }
        </Grid>
      )
  }
}

export default Filters;