import React from 'react';

import DateDivider from '../common/DateDivider';

const AppointmentGroups = props => {

  const { date, orders, Component, startVideoCall, updateStatus, isUpdatingStatus, updatingAppointmentId } = props;

  return (
    <>
      <DateDivider 
        date={date}
      />
      {
        orders.map(order => (
          <Component
            key={order.appointmentId} 
            order={order}
            updateStatus={ () => updateStatus(order.appointmentId, order.itemId, date) }
            isUpdatingStatus={ order.appointmentId === updatingAppointmentId && isUpdatingStatus }
            startVideoCall={startVideoCall}
          />
        ))
      }
    </>
  )
}

export default AppointmentGroups;