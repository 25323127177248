import React, { use } from 'react';
import { Route, Redirect } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const PrivateRoute = ({ component: Component, ...rest }) => {

  const isAuthenticated = useAuth();
  
  return (
    <Route {...rest} render={(props) => (
      isAuthenticated ? <Component {...props} {...rest} /> : <Redirect to={{ pathname: '/login', state: { from: props.location }}} /> )}
    />
  );
}

export default PrivateRoute;