import { get } from 'lodash';

import API_INSTANCE from '../../helpers/apiInstance';
import * as constants from '../constants/earnings';
import {
  DEFAULT_ERROR_MESSAGE
} from '../../constants/messages';
import { 
  formatEarnings
} from '../../helpers/actionHelpers';
import {
  showAlert
} from './alerts';

const dispatchFetchEarnings = isFetchingEarnings => ({
  type: constants.ACTION_FETCH_EARNINGS,
  isFetchingEarnings: isFetchingEarnings
});

const dispatchEarnings = data => ({
  type: constants.ACTION_EARNINGS,
  earnings: data.earnings,
  totalEarnings: data.totalEarnings
});

const fetchEarnings = locationId => async (dispatch, getState) => {
  
  dispatch(dispatchFetchEarnings(true));
  try {
    const vendorId = get(getState(), ['auth', 'vendorData', 'id'], '');
    const options = {
      url: `${constants.FETCH_EARNINGS_URL}?vendor_id=${vendorId}&location_id=${locationId}`,
      method: constants.FETCH_EARNINGS_METHOD
    }
    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {
      dispatch(dispatchEarnings(formatEarnings(get(response, ['data', 0, 'order_count'], {}))));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch (error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchFetchEarnings(false));
}

export {
  fetchEarnings
}