import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import Layout from '../../hocs/DefaultLayout';
import Loader from '../../components/UI/Loader';
import Empty from '../../components/UI/Empty';
import Filters from '../../components/Filters/OrderFilters';
import OrderGroups from '../../components/BuyMedicine/OrderGroups';
import OrderHistoryItem from '../../components/BuyMedicine/OrderHistoryItem';

import { fetchOrders } from '../../store/actions/orders';
import { NO_ORDERS } from '../../constants/messages';

class OrderHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      isFetchingOrders: true
    }
  }

  componentDidMount() {

    if(!_isEmpty(this.props.locations.selectedLocation)) {
      this.manageState();
    }
  }

  componentDidUpdate (prevProps) {

    if(prevProps.locations.selectedLocation !== this.props.locations.selectedLocation) {
      this.manageState();
    }
  }

  manageState = () => {
    const { fetchOrders } = this.props;
    fetchOrders();
    this.setState({
      isFetchingOrders: false
    });
  }

  _renderOrders = () => {
    const { orders } = this.props;
    const { isFetchingOrders } = this.state;
    const data = _get(orders, 'orderHistory', []);
    let markup = null;

    if(orders.isFetchingOrders || isFetchingOrders) {
      return <Loader />;
    }
    else if(_isEmpty(data)) {
      return (
        <Empty 
          message={NO_ORDERS}
        />
      );
    }
    else {
      markup = (
        _map(data, (orderGroup, date) => (
          <OrderGroups 
            key={date}
            date={date}
            orders={orderGroup}
            Component={OrderHistoryItem}
          />
        ))
      )
    }

    return (
      <section className='bm-orders-container'>
        {
          markup
        }
      </section>
    );
  }

  render() {
    return (
      <Layout>
        <Filters 
          component={'OrderHistory'}
          locationLabel={'Pharmas'}
          handleStatusChange={ this.handleStatusChange }
        />
        {
          this._renderOrders()
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  isAutoLoggingIn: state.auth.isAutoLoggingIn,
  orders: state.orders,
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchOrders: () => dispatch(fetchOrders())
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);