import { cloneDeep } from 'lodash';

import * as constants from '../constants/earnings';
import * as loginConstants from '../constants/auth';

const initialState = {
  isFetchingEarnings: false,
  earnings: {},
  totalEarnings: 0
}

const earningsReducer = (state = cloneDeep(initialState), action) => {

  switch(action.type) {
    case constants.ACTION_FETCH_EARNINGS:
      return {
        ...cloneDeep(state),
        isFetchingEarnings: action.isFetchingEarnings
      };

    case constants.ACTION_EARNINGS:
      return {
        ...cloneDeep(state),
        earnings: action.earnings,
        totalEarnings: action.totalEarnings
      };

    case loginConstants.ACTION_LOGOUT:
      return cloneDeep(initialState);

    default:
      return state;
  }
}

export default earningsReducer;
