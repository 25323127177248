import { isEmpty } from 'lodash';

export const inputValidationHelper = (field, value) => { 

  switch(field) {

    case 'email':
      if(isEmpty(value)) return { isValid: false, reason: 'Email cannot be empty' };
      if(!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) return { isValid: false, reason: 'Invalid Email' };
      return { isValid: true };

    case 'password':
      if(isEmpty(value)) return { isValid: false, reason: 'Password cannot be empty' };
      if(value.length < 6) return { isValid: false, reason: 'Password should have atleast 6 characters' };
      return { isValid: true };

    case 'name':
    case 'displayName':
    case 'username':
    case 'name': 
    case 'city':
    case 'address':
    case 'building':
    case 'street':
      if(isEmpty(value) || value.length < 3) return { isValid: false, reason: 'Invalid, Should have atleast 3 characters' };
      return { isValid: true };

    case 'state':
      if(isEmpty(value) || value.length < 3) return { isValid: false, reason: 'Invalid, Please select state' };
      return { isValid: true };
    
    case 'mobileNumber': 
    case 'telephone': 
      if(isEmpty(value) || !value.match(/^\d{10}$/)) return { isValid: false, reason: 'Mobile Number should have 10 digits' };
      return { isValid: true };

    case 'zipcode': 
      if(isEmpty(value) || !value.match(/^\d{6}$/)) return { isValid: false, reason: 'Pincode should have 6 digits' };
      return { isValid: true };

    case 'age': 
      if(isEmpty(value) || !value.match(/^\d{2}$/) || value <= 0) return { isValid: false, reason: 'Invalid age' };
      return { isValid: true };

    case 'gender': 
      const genders = ['male', 'female'];
      if(isEmpty(value) || genders.indexOf(value) === -1) return { isValid: false, reason: 'Please select gender' };
      return { isValid: true };

    case 'quantityConfirmed':
    case 'eodInventory':
      if(!value.toString().match(/^\d+$/)) return { isValid: false, reason: 'Invalid value' };
      return { isValid: true };

    default: return { isValid: true };
  }
}