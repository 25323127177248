import * as constants from '../constants/alerts';

const initialState = {
  showAlert: false,
  message: '',
  messageType: 'info'
}

const alertsReducer = (state = {...initialState}, action) => {

  switch(action.type) {
    case constants.SHOW_ALERT:
      return {
        showAlert: true, 
        message: action.message,
        messageType: action.messageType,
      };

    case constants.HIDE_ALERT:
      return {
        ...initialState
      };

    default:
      return state;
  }
}

export default alertsReducer;