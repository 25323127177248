import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import Loader from '../../components/UI/Loader';
import AvailabilityItem from './AvailabilityItem';

import { fetchLocationLogistics, updateLocationLogistics } from '../../store/actions/locations';

class Availability extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      selectedLogistic: 0
    }
  }

  componentDidMount() {
    this.manageState();
  }

  componentDidUpdate (prevProps) {

    if(prevProps.locationId !== this.props.locationId) this.manageState();
  }

  manageState = () => {
    const { fetchLocationLogistics, locationId } = this.props;
    fetchLocationLogistics(locationId);
  }

  close = () => {
    this.setState({
      selectedLogistic: -1
    });
  }

  handleActiveLogistic = selectedLogistic => {

    if(this.state.selectedLogistic === selectedLogistic || this.props.locations.isUpdatingLocationLogistics) return;

    this.setState({
      selectedLogistic: selectedLogistic
    });
  }

  render() {

    const { selectedLogistic } = this.state;
    const { locations } = this.props;

    let markup = null;
    if(locations.isFetchingLocationLogistics) {
      markup = <Loader />;
    }
    else if (_isEmpty(locations.locationLogistics)) {
      markup = 'Nothing to show';
    }
    else {
      markup = (
        _get(locations, 'locationLogistics', []).map((logistic, index) => (
          <AvailabilityItem 
            key={index}
            logistic={logistic}
            active={_get(locations, ['locationLogistics', selectedLogistic], {}).logistic_entity_id === logistic.logistic_entity_id}
            close={ this.close }
            handleActiveLogistic={ () => this.handleActiveLogistic(index) }
          />
        ))
      )
    }

    return (
      <div className='availability'>
        {
          markup
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchLocationLogistics: locationId => dispatch(fetchLocationLogistics(locationId)),
  updateLocationLogistics: (logistics, weekData) => dispatch(updateLocationLogistics(logistics, weekData))
});

export default connect(mapStateToProps, mapDispatchToProps)(Availability);