import _cloneDeep from 'lodash/cloneDeep';

import * as constants from '../constants/ui';

const initialState = {
  networkStatus: 'online',
  isOnline: true,
  width: 0,
  height: 0,
  isSmallScreen: false
}

const uiReducer = (state = _cloneDeep(initialState), action) => {

  switch(action.type) {
    case constants.ACTION_NETWORK_STATUS:
      return {
        ..._cloneDeep(state),
        networkStatus: action.networkStatus,
        isOnline: action.networkStatus === 'online'
      };

    case constants.ACTION_SCREEN_SIZE_CHANGE:
      return {
        ..._cloneDeep(state),
        width: action.width,
        height: action.height,
        isSmallScreen: action.width < 991 || action.height < 576
      };

    default:
      return state;
  }
}

export default uiReducer;