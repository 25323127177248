import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import Layout from '../../hocs/DefaultLayout';
import Loader from '../../components/UI/Loader';
import Empty from '../../components/UI/Empty';
import Filters from '../../components/Filters/OrderFilters';
import AppointmentGroups from '../../components/DoctorAppointment/AppointmentGroups';
import AppointmentHistoryItem from '../../components/DoctorAppointment/AppointmentHistoryItem';

import { fetchAppointments } from '../../store/actions/orders';
import { NO_APPOINTMENTS } from '../../constants/messages';

class AppointmentHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      isFetchingAppointments: true
    }
  }

  componentDidMount() {

    if(!_isEmpty(this.props.locations.selectedLocation)) {
      this.manageState();
    }
  }

  componentDidUpdate (prevProps) {

    if(prevProps.locations.selectedLocation !== this.props.locations.selectedLocation) {
      this.manageState();
    }
  }

  manageState = () => {
    const { fetchAppointments } = this.props;
    fetchAppointments();
    this.setState({
      isFetchingAppointments: false
    });
  }

  _renderAppointments = () => {
    const { orders } = this.props;
    const { isFetchingAppointments } = this.state;
    const data = _get(orders, 'appointmentHistory', []);
    let markup = null;

    if(orders.isFetchingAppointments || isFetchingAppointments) {
      return <Loader />;
    }
    else if(_isEmpty(data)) {
      return (
        <Empty 
          message={NO_APPOINTMENTS}
        />
      );
    }
    else {
      markup = (
        _map(data, (orderGroup, date) => (
          <AppointmentGroups 
            key={date}
            date={date}
            orders={orderGroup}
            Component={AppointmentHistoryItem}
          />
        ))
      )
    }

    return (
      <section className='orders-container'>
        {
          markup
        }
      </section>
    );
  }

  render() {
    return (
      <Layout>
        <Filters 
          component={'AppointementHistory'}
          locationLabel={'Clinics'}
          handleStatusChange={ this.handleStatusChange }
        />
        {
          this._renderAppointments()
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  isAutoLoggingIn: state.auth.isAutoLoggingIn,
  orders: state.orders,
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchAppointments: () => dispatch(fetchAppointments())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentHistory);