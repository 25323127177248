export const DEFAULT_ERROR_MESSAGE = 'Oops! Something went wrong.';
export const LOGISTICS_UPDATED_SUCCESSFULLY = 'Logistics updated successfully!';
export const NO_INVENTORY_DETAILS = 'No inventory details found!';
export const NO_PRODUCTS_FOUND_IN_INVENTORY = 'No products found in inventory';
export const INVENTORY_UPDATE_SUCCESSFUL = 'Inventory updated successfully!';
export const SEARCH_RESULT_EMPTY = 'Search result empty';
export const ORDER_CONFIRMED_SUCCESSFULLY = 'Order confirmed successfully!';
export const NO_APPOINTMENTS = 'No appointments to show!';
export const NO_ORDERS = 'No orders to show!';
export const NO_LAB_TESTS = 'No lab tests to show!';
export const NO_CONFIRMED_APPOINTMENTS = 'No confirmed appointments!';
export const NO_PENDING_APPOINTMENTS = 'No pending appointments!';
export const NO_CONFIRMED_LAB_TESTS = 'No confirmed lab tests!';
export const NO_PENDING_LAB_TESTS = 'No pending lab tests!';
export const NO_CONFIRMED_ORDERS = 'No confirmed orders!';
export const NO_PENDING_ORDERS = 'No pending orders!';
export const APPOINTMENT_CONFIRMED_SUCCESSFULLY = 'Appointment confirmed successfully!';
export const APPOINTMENT_TIME_PASSED = 'Video chat link has been expired!';
export const VIDEO_CALL_ENABLE_MESSAGE = 'Video chat option will be enabled 15 mins before the slot timings';
export const NO_CLINICS = 'No clinics to show!';
export const NO_LABS = 'No labs to show!';
export const NO_PHARMAS = 'No pharmas to show!';
export const DETAILS_UPDATED_SUCCESSFULLY = 'Details updated successfully!';
export const ERROR_WHILE_CONNECTING = 'Oops! Something went wrong while connecting the call!';
export const ERROR_WHILE_DISCONNECTING = 'Oops! Something went wrong while disconnecting the call!';
export const PATIENT_MUTED_THE_AUDIO = 'Patient has muted the audio!';
export const PATIENT_UNMUTED_THE_AUDIO = 'Patient has unmuted the audio!';
export const PATIENT_MUTED_THE_VIDEO = 'Patient has turned the video off!';
export const PATIENT_LEFT_THE_MEETING = 'Patient disconnected the call!';