import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import useStyles from '../../hooks/styles/TimePicker';

const TimePicker = props => {

  const { hasError, label, value, handleChange } = props;

  const classes = useStyles();

  return (
    <TextField
      error={hasError}
      fullWidth={true}
      id='date'
      label={label}
      type='time'
      value={value}
      onChange={handleChange}
      className={classes.root}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}

TimePicker.defaultProps = {
  
  handleChange: () => {}
}

export default TimePicker;