import { makeStyles } from '@material-ui/core/styles';

import {
  PRIMARY,
  NOT_SO_BLACK
} from '../../assets/colors/colors';

const useStyles = makeStyles({
  root: {
    '& span.MuiListItemText-primary': {
      fontSize: '14px',
      color: NOT_SO_BLACK
    },
    '& div.MuiListItemIcon-root': {
      color: NOT_SO_BLACK,
      justifyContent: 'center',
      minWidth: '75px',
      opacity: 0.9
    },
    '& div.MuiListItem-button': {
      paddingTop: '16px',
      paddingBottom: '14px',
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  },
  active: {
    '& span.MuiListItemText-primary': {
      fontSize: '14px',
      color: PRIMARY
    },
    '& div.MuiListItemIcon-root': {
      color: PRIMARY,
      justifyContent: 'center',
      minWidth: '75px',
      opacity: 0.9
    },
    '& div.MuiListItem-button': {
      paddingTop: '16px',
      paddingBottom: '14px',
      paddingLeft: '0px',
      paddingRight: '0px'
    }
  },
});

export default useStyles;