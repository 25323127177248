import { get } from 'lodash';

import API_INSTANCE from '../../helpers/apiInstance';
import * as constants from '../constants/inventory';
import {
  DEFAULT_ERROR_MESSAGE,
  INVENTORY_UPDATE_SUCCESSFUL
} from '../../constants/messages';
import { 
  formatInventoryAfterUpdate
} from '../../helpers/actionHelpers';
import {
  showAlert
} from './alerts';

const dispatchFetchInventory = isFetchingInventory => ({
  type: constants.ACTION_FETCH_INVENTORY,
  isFetchingInventory: isFetchingInventory
});

const dispatchInventory = inventory => ({
  type: constants.ACTION_INVENTORY,
  inventory: inventory
});

const fetchInventory = locationId => async (dispatch, getState) => {
  
  dispatch(dispatchFetchInventory(true));
  try {
    const vendorId = get(getState(), ['auth', 'vendorData', 'id'], '');

    // vendor_type=13
    //console.log()
    const options = {
      url: `${constants.FETCH_INVENTORY_URL}?vendor_id=${vendorId}&loc_id=${locationId}&start_date=2020-09-01`,
      method: constants.FETCH_INVENTORY_METHOD
    }
    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {
      dispatch(dispatchInventory(get(response, ['data', 0, 'inventory_data'], [])));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch (error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchFetchInventory(false));
}

const dispatchUpdateInventory = isUpdatingInventory => ({
  type: constants.ACTION_UPDATE_INVENTORY,
  isUpdatingInventory: isUpdatingInventory
});

const dispatchUpdateInventorySuccess = inventoryUpdated => ({
  type: constants.ACTION_UPDATE_INVENTORY_SUCCESS,
  inventoryUpdated: inventoryUpdated
});

const updateInventory = inventory => async (dispatch, getState) => {
  
  dispatch(dispatchUpdateInventory(true));
  try {
    const vendorName = get(getState(), ['auth', 'vendorData', 'name'], '');

    const options = {
      url: constants.UPDATE_INVENTORY_URL,
      method: constants.UPDATE_INVENTORY_METHOD,
      data: {
        offer_id: inventory.offerId,
        qty_confirmed: inventory.quantityConfirmed,
        eod_inventory: inventory.eodInventory,
        vendor_name: vendorName
      }
    }
    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {
      dispatch(dispatchUpdateInventorySuccess(true));
      dispatch(showAlert(INVENTORY_UPDATE_SUCCESSFUL, 'success'));
      dispatch(dispatchInventory(formatInventoryAfterUpdate(get(getState(), ['inventory', 'inventory'], []), get(response, ['data', 0, 'inventory_data'], []))));
    }
    else {
      dispatch(dispatchUpdateInventorySuccess(true));
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch (error) {
    dispatch(dispatchUpdateInventorySuccess(true));
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchUpdateInventory(false));
}

export {
  fetchInventory,
  updateInventory
}