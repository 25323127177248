import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useSelector } from 'react-redux';

const Network = props => {

  const isOnline = useSelector(state => state.ui.isOnline);

  return (
    <Snackbar 
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!isOnline}
    >
      <MuiAlert 
        severity='error'
        elevation={6} 
        variant='filled'
        className='mp-alert'
      >
        No connection - check your network!
      </MuiAlert>
    </Snackbar>
  )
}

export default Network;