import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import Layout from '../../hocs/DefaultLayout';
import Loader from '../../components/UI/Loader';
import Empty from '../../components/UI/Empty';
import Filters from '../../components/Filters/OrderFilters';
import OrderGroups from '../../components/BuyMedicine/OrderGroups';
import OrderItem from '../../components/BuyMedicine/OrderItem';

import { fetchOrders, updateOrderStatus } from '../../store/actions/orders';
import { NO_CONFIRMED_ORDERS, NO_PENDING_ORDERS } from '../../constants/messages';

class Orders extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      isFetchingOrders: true,
      updatingOrderId: '',
      status: 'PENDING'
    }
  }

  componentDidMount() {

    if(!_isEmpty(this.props.locations.selectedLocation)) {
      this.manageState();
    }
  }

  componentDidUpdate (prevProps) {

    if(prevProps.locations.selectedLocation !== this.props.locations.selectedLocation) {
      this.manageState();
    }
  }

  manageState = () => {
    const { fetchOrders } = this.props;
    fetchOrders();
    this.setState({
      isFetchingOrders: false
    });
  }

  handleStatusChange = status => {
    this.setState({
      status: status
    });
  }

  handleUpdateStatus = (orderId, itemIds, date) => {
    const { updateOrderStatus, orders } = this.props;
    if(orders.isUpdatingStatus) return;

    this.setState({
      updatingOrderId: orderId
    }, () => updateOrderStatus(orderId, itemIds, date));
  }

  _renderOrders = () => {
    const { orders } = this.props;
    const { isFetchingOrders, status, updatingOrderId } = this.state;
    const data = _get(orders, ['orders', status], []);
    let markup = null;

    if(orders.isFetchingOrders || isFetchingOrders) {
      return <Loader />;
    }
    else if(_isEmpty(data)) {
      return (
        <Empty 
          message={ status === 'CONFIRMED' ? NO_CONFIRMED_ORDERS : NO_PENDING_ORDERS }
        />
      )
    }
    else {
      markup = (
        _map(data, (orderGroup, date) => (
          <OrderGroups 
            key={date}
            date={date}
            orders={orderGroup}
            updateStatus={this.handleUpdateStatus}
            Component={OrderItem}
            updatingOrderId={updatingOrderId}
            isUpdatingStatus={orders.isUpdatingStatus}
          />
        ))
      )
    }
    
    return (
      <section className='bm-orders-container'>
        {
          markup
        }
      </section>
    );
  }

  render() {

    const { status } = this.state;

    return (
      <Layout>
        <Filters 
          component={'Orders'}
          activeStatus={status}
          locationLabel={'Pharmas'}
          handleStatusChange={ this.handleStatusChange }
        />
        {
          this._renderOrders()
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  isAutoLoggingIn: state.auth.isAutoLoggingIn,
  orders: state.orders,
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchOrders: () => dispatch(fetchOrders()),
  updateOrderStatus: (appointmentId, itemId, date) => dispatch(updateOrderStatus(appointmentId, itemId, date))
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);