import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import Layout from '../../hocs/DefaultLayout';
import Loader from '../../components/UI/Loader';
import Empty from '../../components/UI/Empty';
import ClinicTabs from '../../components/DoctorAppointment/ClinicTabs';
import ClinicItem from '../../components/DoctorAppointment/ClinicItem';

import LocationDetails from '../LocationDetails/LocationDetails';
import Availability from '../Availablity/Availability';
import Earnings from '../../components/Earnings/Earnings';

import { fetchLocations } from '../../store/actions/locations';
import { NO_CLINICS } from '../../constants/messages';

// const Availability = React.lazy(() => import('../Availablity/Availability'));
// const Earnings = React.lazy(() => import('../../components/Earnings/Earnings'));

class Clinics extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      isFetchingClinics: false,
      selectedClinicIndex: 0,
      activeTab: 'DETAILS'
    }
  }

  componentDidMount() {
    if(_isEmpty(this.props.locations.locationsList)) this.fetchLocationsHelper();
  }

  fetchLocationsHelper = () => {
    const { fetchLocations } = this.props;

    this.setState({
      isFetchingClinics: true
    }, () => {
      fetchLocations();
      this.setState({
        isFetchingClinics: false
      });
    });
  }

  handleSelectClinic = index => {
    let { selectedClinicIndex } = this.state;
    let { locations } = this.props;

    if(locations.isUpdatingLocationDetails || index === selectedClinicIndex) return;

    this.setState({
      selectedClinicIndex: index
    });
  }

  handleTabChange = tab => {

    const { activeTab } = this.state;
    if(tab === activeTab) return null;

    this.setState({
      activeTab: tab
    });
  }

  _renderClinics = () => {
    const { locations } = this.props;
    const { isFetchingClinics, selectedClinicIndex } = this.state;
    if(locations.isFetchingLocations || isFetchingClinics) {
      return <Loader />
    }
    else if(_isEmpty(locations.locationsList)) {
      return (
        <Empty 
          message={NO_CLINICS}
        />
      )
    }
    else {
      return (
        <div className='locations-list'>
          {
            locations.locationsList.map((location, index) => (
              <ClinicItem 
                key={location.location_id}
                location={location}
                isSelected={index === selectedClinicIndex}
                selectClinic={ () => this.handleSelectClinic(index) }
              />
            ))
          }
        </div>
      )
    }
  }

  _renderTabPanels = () => {

    const { locations } = this.props;
    const { selectedClinicIndex, activeTab } = this.state; 
    switch(activeTab) {
      case 'DETAILS':
        return (
          <LocationDetails 
            location={_get(locations, ['locationsList', selectedClinicIndex], {})}
          />
        );

      case 'AVAILABILITY':
        return (
          <Availability 
            locationId={_get(locations, ['locationsList', selectedClinicIndex, 'location_id'], '')}
          />
        );

      case 'EARNINGS':
        return (
          <Earnings 
            locationId={_get(locations, ['locationsList', selectedClinicIndex, 'location_id'], '')}
          />
        );
    }
  }

  _renderTabs = () => {

    const { locations } = this.props;
    const { isFetchingClinics, selectedClinicIndex, activeTab } = this.state;
    if(locations.isFetchingLocations || isFetchingClinics || _isEmpty(locations.locationsList)) return null;

    return (
      <div className='tab-container'>
        <ClinicTabs 
          activeTab={activeTab}
          handleTabChange={this.handleTabChange}
        />
        {
          this._renderTabPanels()
        }
      </div>
    )
  }

  render() {
    return (
      <Layout>
        {
          this._renderClinics()
        }
        {
          this._renderTabs()
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  isAutoLoggingIn: state.auth.isAutoLoggingIn,
  isLoggedIn: state.auth.isLoggedIn,
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchLocations: () => dispatch(fetchLocations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Clinics);