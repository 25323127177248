import React from 'react';
import TextField from '@material-ui/core/TextField';

import useStyles from '../../hooks/styles/TextInput';

const TextInput = props => {

  const { required, readOnly, fullWidth, label, type, hasError, errorMessage, value, handleChange, handleKeyDown } = props;

  const classes = useStyles();

  return (
    <TextField
      required={required}
      fullWidth={fullWidth}
      label={label}
      type={type}
      value={value}
      error={hasError}
      helperText={errorMessage}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      classes={classes}
      InputProps={{
        readOnly: readOnly,
      }}
    />
  );
}

TextInput.defaultProps = {
  required: false,
  label: '',
  type: 'text',
  readOnly: false,
  fullWidth: false,
  hasError: false,
  errorMessage: '',
  handleChange: () => {},
  handleKeyDown: () => {}
}

export default TextInput;