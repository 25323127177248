import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import InitialComponent from '../containers/InitialComponent';
import PrivateRoute from '../Routes/PrivateRoute';

import Appointments from '../containers/DoctorAppointment/Appointments';
import AppointmentHistory from '../containers/DoctorAppointment/AppointmentHistory';
import Clinics from '../containers/DoctorAppointment/Clinics';
import Profile from '../containers/Profile/Profile';
import Inventory from '../containers/Inventory/Inventory';

import LabTests from '../containers/LabTests/LabTests';
import LabTestHistory from '../containers/LabTests/LabTestHistory';
import Labs from '../containers/LabTests/Labs';

import Orders from '../containers/BuyMedicine/Orders';
import OrderHistory from '../containers/BuyMedicine/OrderHistory';
import Pharmas from '../containers/BuyMedicine/Pharmas';

import Services from '../containers/HomeCareServices/Services';
import DownloadApp from '../components/common/DownloadApp';

const Login = React.lazy(() => import('../containers/Auth/Login'));
const Logout = React.lazy(() => import('../containers/Auth/Logout'));
const Dashboard = React.lazy(() => import('../containers/Dashboard'));
const Alert = React.lazy(() => import('../components/UI/Alert'));

const StartVideoCall =  React.lazy(() => import('../containers/Video/StartVideoCall'));
const VideoCall =  React.lazy(() => import('../containers/Video/VideoCall'));

// const Appointments = React.lazy(() => import('../containers/DoctorAppointment/Appointments'));
// const AppointmentHistory = React.lazy(() => import('../containers/DoctorAppointment/AppointmentHistory'));
// const Clinics = React.lazy(() => import('../containers/DoctorAppointment/Clinics'));

const loading = (
  <div className='pt-3 text-center'>
    Loading
  </div>
);

class Main extends Component {

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Alert />
          <InitialComponent />
          <Dashboard />
          <Switch>
            <Route path='/login' name='Login' render={props => <Login {...props}/>} />
            <Route path='/logout' name='Logout' render={props => <Logout {...props}/>} />
            <Route path='/download-app' name='Download App' render={props => <DownloadApp {...props} />} />
            <PrivateRoute path='/appointment-history' component={AppointmentHistory} />
            <PrivateRoute path='/appointments' component={Appointments} />
            <PrivateRoute path='/online-appointment' component={VideoCall} />
            <PrivateRoute path='/online-appointment-preview' component={StartVideoCall} />
            <PrivateRoute path='/clinics' component={Clinics} />
            <PrivateRoute path='/profile' component={Profile} />
            <PrivateRoute path='/inventory' component={Inventory} />
            <PrivateRoute path='/lab-test-history' component={LabTestHistory} />
            <PrivateRoute path='/lab-tests' component={LabTests} />
            <PrivateRoute path='/labs' component={Labs} />
            <PrivateRoute path='/order-history' component={OrderHistory} />
            <PrivateRoute path='/orders' component={Orders} />
            <PrivateRoute path='/pharmas' component={Pharmas} />
            <PrivateRoute path='/home-care-services' component={Services} />
            <PrivateRoute exact path='/' component={Dashboard} />
            <Redirect to='/login' />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default Main;
