import { makeStyles } from '@material-ui/core/styles';

import {
  TERTIARY
} from '../../assets/colors/colors';

const useStyles = makeStyles({
  root: {
    '& span.MuiTypography-root': {
      fontSize: '16px',
      color: TERTIARY
    }
  }
});

export default useStyles;