import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import ChevronRight from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';

import TextInput from '../../components/UI/TextInput';
import SelectInput from '../../components/UI/SelectInput';
import SwitchInput from '../../components/UI/SwitchInput';

import { fetchStatesList, updateLocationDetails } from '../../store/actions/locations';
import { inputValidationHelper } from '../../helpers/validations';

class LocationDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      inputConfig: {
        name: {
          required: true,
          fullWidth: true,
          label: 'Name',
          placeholder: 'Name',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('name', event.target.value)
        },
        displayName: {
          required: true,
          fullWidth: true,
          label: 'Display name',
          placeholder: 'Display name',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('displayName', event.target.value)
        },
        email: {
          required: true,
          keyboardType: 'email-address',
          fullWidth: true,
          label: 'Email',
          placeholder: 'Email',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('email', event.target.value)
        },
        telephone: {
          required: true,
          hasPrefix: true,
          keyboardType: 'phone-pad',
          maxLength: 10,
          prefix: '+91',
          fullWidth: true,
          label: 'Phone number',
          placeholder: 'Phone number',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('telephone', event.target.value)
        },
        building: {
          required: true,
          fullWidth: true,
          label: 'Building',
          placeholder: 'Building',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('building', event.target.value)
        },
        street: {
          required: true,
          fullWidth: true,
          label: 'Street',
          placeholder: 'Street',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('street', event.target.value)
        },
        city: {
          required: true,
          fullWidth: true,
          label: 'City',
          placeholder: 'City',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('city', event.target.value)
        },
        state: {
          required: true,
          fullWidth: true,
          label: 'State',
          placeholder: 'State',
          options: [],
          value: '',
          name: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('state', event.target.value)
        },
        zipcode: {
          required: true,
          fullWidth: true,
          label: 'Pincode',
          placeholder: 'Pincode',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('zipcode', event.target.value)
        },
        status: {
          label: 'is Active?',
          value: false,
          handleChange: (event) => this.handleInputChange('status', event.target.value)
        }
      },
      locationId: ''
    }
  }

  componentDidMount () {
    const { locations, fetchStatesList, location } = this.props;

    const inputConfig = this.populateInputConfig(location);

    this.setState({
      inputConfig,
      locationId: location.location_id
    });

    if(locations.stateOptions) fetchStatesList();
  }

  componentDidUpdate (prevProps) {

    if(!_isEqual(prevProps.location, this.props.location)) {
      const inputConfig = this.populateInputConfig(this.props.location);

      this.setState({
        inputConfig,
        locationId: this.props.location.location_id
      });
    }
  }

  populateInputConfig = location => {
    
    const inputConfig = _cloneDeep(this.state.inputConfig);

    inputConfig['name']['value'] = _get(location, 'loc_name', '');
    inputConfig['displayName']['value'] = _get(location, 'loc_cust_disp_name', '');
    inputConfig['email']['value'] = _get(location, 'loc_email', '');
    inputConfig['telephone']['value'] = _get(location, 'loc_phone', '');
    inputConfig['building']['value'] = _get(location, ['address', 'street', 0], '');
    inputConfig['street']['value'] = _get(location, ['address', 'street', 1], '');
    inputConfig['city']['value'] = _get(location, ['address', 'loc_city'], '');
    inputConfig['state']['value'] = _get(location, ['address', 'loc_state', 'region_id'], '');
    inputConfig['state']['name'] = _get(location, ['address', 'loc_state', 'name'], '');
    inputConfig['zipcode']['value'] = _get(location, ['address', 'loc_postcode'], '');
    inputConfig['status']['value'] = _get(location, 'status', '') === '1';

    return inputConfig;
  }

  handleInputChange = (input, value) => {
    const inputConfig = _cloneDeep(this.state.inputConfig);

    switch(input) {
      default: 
        inputConfig[input]['value'] = value;
        break;

      case 'state':
        const { locations } = this.props;
        const state = locations.statesList.filter(option => option.id === value)
        inputConfig[input]['value'] = value;
        inputConfig[input]['name'] = _get(state, [0, 'name'], '');
        break;

      case 'status':
        inputConfig[input]['value'] = !inputConfig[input]['value'];
        break;
    }

    this.setState({
      inputConfig
    });
  }

  validateUserInputs = (input, inputConfig, isFormValid) => {
    
    const validationResult = inputValidationHelper(input, input === 'state' ? inputConfig[input]['name'] : inputConfig[input]['value']);
    
    if(!validationResult.isValid)
    {
      inputConfig[input]['hasError'] = true;
      inputConfig[input]['errorMessage'] = validationResult.reason;
      isFormValid = false;
    }
    else {
      inputConfig[input]['hasError'] = false;
      inputConfig[input]['errorMessage'] = ''
    }
    return isFormValid;
  }

  handleUpdateDetails = () => {

    let isFormValid = true;
    const inputConfig = _cloneDeep(this.state.inputConfig);
    const { locationId } = this.state;
    const { updateLocationDetails, locations } = this.props;

    if(locations.isUpdatingLocationDetails) return;

    isFormValid = this.validateUserInputs('name', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('displayName', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('email', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('telephone', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('building', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('street', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('city', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('state', inputConfig, isFormValid);
    isFormValid = this.validateUserInputs('zipcode', inputConfig, isFormValid);

    this.setState({
      inputConfig
    });

    if(isFormValid) updateLocationDetails({
      locationId: locationId,
      name: _get(inputConfig, ['name', 'value'], ''),
      displayName: _get(inputConfig, ['displayName', 'value'], ''),
      email: _get(inputConfig, ['email', 'value'], ''),
      telephone: _get(inputConfig, ['telephone', 'value'], ''),
      building: _get(inputConfig, ['building', 'value'], ''),
      street: _get(inputConfig, ['street', 'value'], ''),
      city: _get(inputConfig, ['city', 'value'], ''),
      stateId: _get(inputConfig, ['state', 'value'], ''),
      state: _get(inputConfig, ['state', 'name'], ''),
      zipcode: _get(inputConfig, ['zipcode', 'value'], ''),
      status: _get(inputConfig, ['status', 'value'], '') ? '1' : '0',
    });
  }

  render() {

    const { inputConfig } = this.state;
    const { locations } = this.props;

    return (
      <div className='bm-location-details'>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <TextInput 
                { ...inputConfig.name }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <TextInput 
                { ...inputConfig.displayName }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <TextInput 
                { ...inputConfig.email }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <TextInput 
                { ...inputConfig.telephone }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <TextInput 
                { ...inputConfig.building }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <TextInput 
                { ...inputConfig.street }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <TextInput 
                { ...inputConfig.city }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <SelectInput 
                { ...inputConfig.state }
                options={locations.statesList}
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='bm-location-details__input'>
              <TextInput 
                { ...inputConfig.zipcode }
              />
            </Grid>
            <Grid item xs={6} lg={6} xl={4} className='bm-location-details__input bm-location-details__input-group'>
              <SwitchInput 
                { ...inputConfig.status }
              />
              <Button
                variant='contained'
                className='mp-button list-action-button'
                onClick={ this.handleUpdateDetails }
                endIcon={ locations.isUpdatingLocationDetails ? <CircularProgress size={16} color={'inherit'}  /> : <ChevronRight /> }
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  login: state.auth,
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchStatesList: () => dispatch(fetchStatesList()),
  updateLocationDetails: (data) => dispatch(updateLocationDetails(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails);