import { makeStyles } from '@material-ui/core/styles';

import {
  PRIMARY,
  ERROR,
  TERTIARY
} from '../../assets/colors/colors';

const useStyles = makeStyles({
  root: {
    '& label': {
      fontSize: '16px',
      color: TERTIARY
    },
    '& label.Mui-focused': {
      color: PRIMARY
    },
    '& label.Mui-error': {
      color: ERROR
    },
    '& p.Mui-error': {
      fontSize: '12px',
    },
    '& .MuiInput-input':{ 
      fontSize: '16px',
      paddingTop: '6px',
      paddingBottom: '6px',
      color: TERTIARY,
      fontWeight: 400
    },
    '& .MuiInput-underline': {
      borderBottomWidth: 2,
      borderBottomColor: TERTIARY
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: PRIMARY
    },
    '& .Mui-error': {
      borderBottomColor: ERROR
    },
    '& .Mui-error:after': {
      borderBottomColor: ERROR
    }
  }
});

export default useStyles;