import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import Network from '../components/common/Network';

import { newtworkChange, screenSizeChange } from '../store/actions/ui';

class InitialComponent extends React.Component {

  componentDidMount() {

    // to set the screen size at the beginning
    this.handleScreenSize('addEvent');

    window.addEventListener('online', () => this.handleNetworkStatus('online', 'addEvent'));
    window.addEventListener('offline', () => this.handleNetworkStatus('offline', 'addEvent'));
    window.addEventListener('resize', () => this.handleScreenSize('addEvent'));
  }

  componentWillUnmount() {
    window.removeEventListener('online', () => this.handleNetworkStatus('online', 'removeEvent'));
    window.removeEventListener('offline', () => this.handleNetworkStatus('offline', 'removeEvent'));
    window.removeEventListener('resize', () => this.handleScreenSize('removeEvent'));
  }

  handleNetworkStatus = (status, type) => {

    switch(type) {

      case 'addEvent':
        this.props.newtworkChange(status);
        break;

      case 'removeEvent':
        break;
    }
  }

  handleScreenSize = type => {
    switch(type) {
      case 'addEvent':
        this.props.screenSizeChange(window.outerWidth, window.outerHeight);
        break;

      case 'removeEvent':
        break;
    }
  }

  render() {
    return <Network />;
  }
}

// const mapStateToProps = state => ({
//   login: state.auth
// });

const mapDispatchToProps = dispatch => ({
  newtworkChange: status => dispatch(newtworkChange(status)),
  screenSizeChange: (width, height) => dispatch(screenSizeChange(width, height))
});

export default connect(null, mapDispatchToProps)(InitialComponent);