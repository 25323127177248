import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import ArrowForwardSharpIcon from '@material-ui/icons/ArrowForwardSharp';

import Loader from '../UI/Loader';

import { fetchEarnings } from '../../store/actions/earnings';

const EarningsItem = props => {

  const { duration, amount, orders } = props;

  return (
    <Grid item xs={3} className='earnings-item'>
      <div className='earnings-item__header'>
        <span>{ duration }</span>
      </div>
      <div className='earnings-item__body'>
        <span>INR</span>
        <span>{ amount }</span>
      </div>
      <div className='earnings-item__footer'>
        <span>Orders</span>
        <span>{ orders }</span>
      </div>
    </Grid>
  )
}

const TotalEarnings = props => {

  const { totalEarnings } = props;

  return (
    <Grid item xs={12} className='total-earnings'>
      <span>Total Earnings:</span>

      <div className='total-earnings__amount'>
        <span>INR</span>
        <span>{ totalEarnings }</span>
      </div>
    </Grid>
  )
}

const Earnings = props => {

  const { locationId } = props;
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const earnings = useSelector(state => state.earnings);
  let markup = null;

  useEffect(() => {
    dispatch(fetchEarnings(locationId));
    setIsLoading(false);
  }, [locationId]);

  if(isLoading || earnings.isFetchingEarnings) {
    markup = <Loader />;
  }
  else {
    markup = (
      <Grid container spacing={3} className='earnings'>
        <TotalEarnings
          totalEarnings={earnings.totalEarnings} 
        />
        {
          Object.keys(earnings.earnings).map(duration => (
            <EarningsItem 
              key={duration}
              duration={duration}
              amount={_get(earnings, ['earnings', duration, 'revenue'], 0)}
              orders={_get(earnings, ['earnings', duration, 'count'], 0)}
            />
          ))
        }
      </Grid>
    )
  }

  return markup;
}

export default Earnings;