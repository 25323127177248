import { cloneDeep, isEmpty, get, size } from 'lodash';

import * as constants from '../constants/locations';
import * as loginConstants from '../constants/auth';

const initialState = {
  isFetchingLocations: false,
  isFetchingLocationLogistics: false,
  isUpdatingLocationLogistics: false,
  isFetchingStateList: false,
  isUpdatingLocationDetails: false,
  locationsList: [],
  showSelectLocations: true,
  selectedLocation: '',
  locationLogistics: [],
  statesList: [],
  stateOptions: [],
  updatedLocationData: false
}

const locationsReducer = (state = cloneDeep(initialState), action) => {
  
  switch(action.type) {

    case constants.ACTION_FETCH_LOCATIONS:
      return {
        ...cloneDeep(state),
        isFetchingLocations: action.isFetchingLocations
      }

    case constants.ACTION_LOCATION_DATA:
      return {
        ...cloneDeep(state),
        locationsList: action.locationsList,
        showSelectLocations: size(action.locationsList) > 1
      }

    case constants.CHANGE_SELECTED_LOCATION:
      return { 
        ...cloneDeep(state),
        selectedLocation: action.selectedLocation
      };

    case constants.ACTION_FETCH_LOCATION_LOGISTICS:
      return {
        ...cloneDeep(state),
        isFetchingLocationLogistics: action.isFetchingLocationLogistics
      }

    case constants.ACTION_LOCATION_LOGISTICS_DATA:
      return {
        ...cloneDeep(state),
        locationLogistics: action.locationLogistics
      }

    case constants.ACTION_UPDATE_LOCATION_LOGISTICS:
      return {
        ...cloneDeep(state),
        isUpdatingLocationLogistics: action.isUpdatingLocationLogistics
      }

    case constants.ACTION_FETCH_STATE_LIST:
      return {
        ...cloneDeep(state),
        isFetchingStateList: action.isFetchingStateList
      }

    case constants.ACTION_STATE_LIST:
      return {
        ...cloneDeep(state),
        statesList: action.payload.statesList,
        stateOptions: action.payload.stateOptions
      }

    case constants.ACTION_UPDATE_LOCATION_DETAILS:
      return {
        ...cloneDeep(state),
        isUpdatingLocationDetails: action.isUpdatingLocationDetails,
      }

    case constants.ACTION_UPDATE_LOCATION_DATA:
      return {
        ...cloneDeep(state),
        updatedLocationData: action.updatedLocationData
      }

    case loginConstants.ACTION_LOGOUT:
      return cloneDeep(initialState);

    default:
      return state;
  }
}

export default locationsReducer;