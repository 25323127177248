import * as constants from '../constants/ui';

const newtworkChange = status => ({
  type: constants.ACTION_NETWORK_STATUS,
  networkStatus: status
});

const screenSizeChange = (width, height) => ({
  type: constants.ACTION_SCREEN_SIZE_CHANGE,
  width: width,
  height: height
});

export {
  newtworkChange,
  screenSizeChange
};