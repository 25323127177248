import React from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';

import useStyles from '../../hooks/styles/Tabs';

const ClinicTabs = props => {

  const { activeTab, handleTabChange } = props;
  const classes = useStyles();

  return (
    <AppBar position='static' className={classes.root}>
      <Tabs
        value={activeTab}
        onChange={(event, value) => handleTabChange(value)}
        variant='scrollable'
        className={classes.root}
      >
        <Tab value='DETAILS' label='Details' />
        <Tab value='AVAILABILITY' label='Availability' />
        <Tab value='EARNINGS' label='Earnings' />
      </Tabs>
    </AppBar>
  );
}

export default ClinicTabs;