import { cloneDeep } from 'lodash';

import * as constants from '../constants/orders';
import * as loginConstants from '../constants/auth';

const initialState = {
  isFetchingOrders: false,
  isFetchingAppointments: false,
  isUpdatingStatus: false,
  orders: {},
  orderHistory: [],
  appointments: {},
  appointmentHistory: []
}

const ordersReducer = (state = cloneDeep(initialState), action) => {

  switch(action.type) {
    case constants.ACTION_FETCH_ORDERS:
      return {
        ...cloneDeep(state),
        isFetchingOrders: action.isFetchingOrders
      };

    case constants.ACTION_ORDERS:
      return {
        ...cloneDeep(state),
        orders: action.orders,
        orderHistory: action.orderHistory,
      };

    case constants.ACTION_FETCH_APPOINTMENTS:
      return {
        ...cloneDeep(state),
        isFetchingAppointments: action.isFetchingAppointments
      };

    case constants.ACTION_APPOINTMENTS:
      return {
        ...cloneDeep(state),
        appointments: action.appointments,
        appointmentHistory: action.appointmentHistory,
      };

    case constants.ACTION_UPDATE_STATUS:
      return {
        ...cloneDeep(state),
        isUpdatingStatus: action.isUpdatingStatus
      };

    case loginConstants.ACTION_LOGOUT:
      return cloneDeep(initialState);

    default:
      return state;
  }
}

export default ordersReducer;