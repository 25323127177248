const URL = 'https://m2.gwsmediversal.in';
const BASE_URL = `${URL}/rest/default/V1/`;
const MEDIA_URL = `${URL}/pub/media`;
const BEARER_TOKEN = 'hza64acedpros0kvnzhfdq4pqloxmuhk';

const config = {
  baseUrl: BASE_URL,
  mediaUrl: MEDIA_URL,
  bearerToken: BEARER_TOKEN,
  since: [
    { value: 'Yesterday', display: 'Yesterday' },
    { value: '2 Days', display: '2 Days' },
    { value: '3 Days', display: '3 Days' },
    { value: '4 Days', display: '4 Days' },
    { value: '5 Days', display: '5 Days' },
    { value: '6 Days', display: '6 Days' },
    { value: 'Few Days', display: 'Few Days' },
    { value: 'A Week', display: 'A Week' },
    { value: '2 Weeks', display: '2 Weeks' },
    { value: '3 Weeks', display: '3 Weeks' },
    { value: 'A Month', display: 'A Month' },
    { value: '2 Months', display: '2 Months' },
    { value: '3 Months', display: '3 Months' },
    { value: 'Few Months', display: 'Few Months' },
    { value: 'A Year', display: 'A Year' }
  ],
  severity: [
    { value: 'Severe', display: 'Severe' },
    { value: 'Moderate', display: 'Moderate' },
    { value: 'Mild', display: 'Mild' }
  ],
  natureOfDiagnosis: [
    { value: 'Provisional', display: 'Provisional' }
  ],
  agoraConfig: {
    appId: 'd52391f2e73842d1a941ce4753229d84',
    channel: 'channel-x',
    uid: Math.floor(Math.random() * 100),
    mode: 'rtc',
    codec: 'h264'
  }
}

export default config;