import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import useStyles from '../../hooks/styles/SwitchInput';

const SwitchInput = props => {

  const { size, label, value, handleChange } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      size={size}
      value={value}
      checked={value}
      control={<Switch size={size} color='primary' className={classes.root} />}
      label={label}
      labelPlacement='start'
      onChange={handleChange}
      className={classes.root}
      style={{ marginLeft: 0 }}
    />
  );
}

SwitchInput.defaultProps = {
  size: 'normal',
  label: '',
  value: false,
  handleChange: () => {}
}

export default SwitchInput;