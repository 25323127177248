export const FETCH_ORDERS_URL = 'vendor/orders/item-details';
export const FETCH_ORDERS_METHOD = 'GET';
export const ACTION_FETCH_ORDERS = 'ACTION_FETCH_ORDERS';
export const ACTION_ORDERS = 'ACTION_ORDERS';

export const FETCH_APPOINTMENTS_URL = 'vendor/orders/item-details';
export const FETCH_APPOINTMENTS_METHOD = 'GET';
export const ACTION_FETCH_APPOINTMENTS = 'ACTION_FETCH_APPOINTMENTS';
export const ACTION_APPOINTMENTS = 'ACTION_APPOINTMENTS';

export const UPDATE_STATUS_URL = 'update-status';
export const UPDATE_STATUS_METHOD = 'PUT';
export const ACTION_UPDATE_STATUS = 'ACTION_UPDATE_STATUS';