import { cloneDeep, isEmpty, size } from 'lodash';

import * as constants from '../constants/auth';

const initialState = {
  isLoggingIn: false,
  isLoggedIn: false,
  isAutoLoggingIn: false,
  isAutoLoginFailed: false,
  vendorData: {},
  enabledCategories: {},
  showSelectCategories: true,
  selectedCategory: ''
}

const authReducer = (state = cloneDeep(initialState), action) => {
  
  switch(action.type) {

    case constants.ACTION_AUTO_LOGIN_FAILED:
      return { 
        ...cloneDeep(state),
        isAutoLoginFailed: action.isAutoLoginFailed
      };

    case constants.ACTION_AUTO_LOGIN:
      return { 
        ...cloneDeep(state),
        isAutoLoggingIn: action.isAutoLoggingIn
      };

    case constants.ACTION_LOGIN:
      return { 
        ...cloneDeep(state),
        isLoggingIn: action.isLoggingIn
      };
    
    case constants.ACTION_USER_DATA:
      return { 
        ...cloneDeep(state),
        isLoggedIn: !isEmpty(action.data.vendorData) && !isEmpty(action.data.enabledCategories),
        vendorData: action.data.vendorData,
        enabledCategories: action.data.enabledCategories,
        showSelectCategories: size(action.data.enabledCategories) > 1
      };

    case constants.CHANGE_SELECTED_CATEGORY:
      return { 
        ...cloneDeep(state),
        selectedCategory: action.selectedCategory
      };

    case constants.ACTION_LOGOUT:
      return cloneDeep(initialState);

    default:
      return state;
  }
}

export default authReducer;