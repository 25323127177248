import { makeStyles } from '@material-ui/core/styles';

import {
  PRIMARY,
  WHITE,
  TERTIARY, 
  NOT_SO_WHITE
} from '../../assets/colors/colors';

const useStyles = makeStyles({
  tableHeaderCell: {
    fontSize: '14px',
    fontWeight: '500',
    color: TERTIARY
  },
  tableBody: {
    background: WHITE
  },
  tableCell: {
    fontSize: '12px'
  },
  caption: {
    fontSize: '12px'
  },
  toolbar: {
    background: WHITE,
    fontSize: '12px'
  }
});

export default useStyles;