import React from 'react';
import Button from '@material-ui/core/Button';

import ChevronRight from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';

import Status from '../UI/Status';
import { PRIMARY } from '../../assets/colors/colors';

const OrderInfo = props => {

  const { title, value } = props;

  return (
    <div className='order-item__info'>
      <span className='order-item__info__title'>{ title }</span>
      <span className='order-item__info__value'>{ value }</span>
    </div>
  )
}

const AppointmentHistoryItem = props => {

  const { order } = props;

  return (
    <div className='order-item'>
      <div className='order-item__patient-details'>
        <div className='order-item__age-gender-box'>
          <span>{ order.gender }</span>
          <span>{ order.age } y</span>
        </div>
        <div className='order-item__details'>
          <span className='order-item__details__patient-name'>{ order.name }</span>
          <Status 
            status={ order.status }
          />
          <OrderInfo 
            title={'Request ID:'}
            value={ order.appointmentId }
          />
          <OrderInfo 
            title={'Slot:'}
            value={ order.appointmentTime }
          />
          <OrderInfo 
            title={'Reason:'}
            value={ order.reasonForVisit }
          />
        </div>
      </div>
      <div className='order-item__action'>
        <Button
          variant='outlined'
          className='mp-button-sm-outline list-action-button vertical-button-group order-list-btn-width'
          // onClick={ this.handleLogin }
          // endIcon={ props.isLoggingIn ? <CircularProgress size={16} color={'inherit'}  /> : <ChevronRight /> }
        >
          View Symptoms
        </Button>
        <Button
          variant='contained'
          className='mp-button-sm list-action-button vertical-button-group order-list-btn-width'
          // onClick={ this.handleLogin }
          // endIcon={ props.isLoggingIn ? <CircularProgress size={16} color={'inherit'}  /> : <ChevronRight /> }
        >
          View Prescription
        </Button>
      </div>
    </div>
  )
}

export default AppointmentHistoryItem;