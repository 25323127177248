import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';

import Layout from '../../hocs/DefaultLayout';
import Loader from '../../components/UI/Loader';
import Empty from '../../components/UI/Empty';
import PharmaTabs from '../../components/BuyMedicine/PharmaTabs';
import PharmaItem from '../../components/BuyMedicine/PharmaItem';

import LocationDetails from '../LocationDetails/LocationDetails';
import Availability from '../Availablity/Availability';
import Earnings from '../../components/Earnings/Earnings';

import { fetchLocations } from '../../store/actions/locations';
import { NO_PHARMAS } from '../../constants/messages';

class Pharmas extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      isFetchingPharmas: false,
      selectedPharmaIndex: 0,
      activeTab: 'DETAILS'
    }
  }

  componentDidMount() {
    if(_isEmpty(this.props.locations.locationsList)) this.fetchLocationsHelper();
  }

  fetchLocationsHelper = () => {
    const { fetchLocations } = this.props;

    this.setState({
      isFetchingPharmas: true
    }, () => {
      fetchLocations();
      this.setState({
        isFetchingPharmas: false
      });
    });
  }

  handleSelectPharma = index => {
    let { selectedPharmaIndex } = this.state;
    let { locations } = this.props;

    if(locations.isUpdatingLocationDetails || index === selectedPharmaIndex) return;

    this.setState({
      selectedPharmaIndex: index
    });
  }

  handleTabChange = tab => {

    const { activeTab } = this.state;
    if(tab === activeTab) return null;

    this.setState({
      activeTab: tab
    });
  }

  _renderPharmas = () => {
    const { locations } = this.props;
    const { isFetchingPharmas, selectedPharmaIndex } = this.state;
    if(locations.isFetchingLocations || isFetchingPharmas) {
      return <Loader />
    }
    else if(_isEmpty(locations.locationsList)) {
      return (
        <Empty 
          message={NO_PHARMAS}
        />
      )
    }
    else {
      return (
        <div className='bm-locations-list'>
          {
            locations.locationsList.map((location, index) => (
              <PharmaItem 
                key={location.location_id}
                location={location}
                isSelected={index === selectedPharmaIndex}
                selectClinic={ () => this.handleSelectPharma(index) }
              />
            ))
          }
        </div>
      )
    }
  }

  _renderTabPanels = () => {

    const { locations } = this.props;
    const { selectedPharmaIndex, activeTab } = this.state; 
    switch(activeTab) {
      case 'DETAILS':
        return (
          <LocationDetails 
            location={_get(locations, ['locationsList', selectedPharmaIndex], {})}
          />
        );

      case 'AVAILABILITY':
        return (
          <Availability 
            locationId={_get(locations, ['locationsList', selectedPharmaIndex, 'location_id'], '')}
          />
        );

      case 'EARNINGS':
        return (
          <Earnings 
            locationId={_get(locations, ['locationsList', selectedPharmaIndex, 'location_id'], '')}
          />
        );
    }
  }

  _renderTabs = () => {

    const { locations } = this.props;
    const { isFetchingPharmas, selectedPharmaIndex, activeTab } = this.state;
    if(locations.isFetchingLocations || isFetchingPharmas || _isEmpty(locations.locationsList)) return null;

    return (
      <div className='tab-container'>
        <PharmaTabs 
          activeTab={activeTab}
          handleTabChange={this.handleTabChange}
        />
        {
          this._renderTabPanels()
        }
      </div>
    )
  }

  render() {
    return (
      <Layout>
        {
          this._renderPharmas()
        }
        {
          this._renderTabs()
        }
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  fetchLocations: () => dispatch(fetchLocations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Pharmas);