export const LOGIN_URL = 'vendor/login';
export const LOGIN_METHOD = 'POST';

export const ACTION_LOGIN = 'ACTION_LOGIN';
export const ACTION_USER_DATA = 'ACTION_USER_DATA';
export const ACTION_AUTO_LOGIN_FAILED = 'ACTION_AUTO_LOGIN_FAILED';
export const ACTION_AUTO_LOGIN = 'ACTION_AUTO_LOGIN';

export const AUTO_LOGIN_URL = 'vendor';
export const AUTO_LOGIN_METHOD = 'GET';

export const CHANGE_SELECTED_CATEGORY = 'CHANGE_SELECTED_CATEGORY';

export const ACTION_LOGOUT = 'ACTION_LOGOUT';