import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ChevronRight from '@material-ui/icons/ChevronRight';
import VideoCall from '@material-ui/icons/VideoCall';
import CircularProgress from '@material-ui/core/CircularProgress';

import Status from '../UI/Status';

const OrderInfo = props => {

  const { title, value, emptySpace } = props;

  return (
    <div className='bm-order-item__info'>
      <span className='bm-order-item__info__title'>{ title }{ emptySpace }</span>
      <span className='bm-order-item__info__value'>{ value }</span>
    </div>
  )
}

const Item = props => {

  const { name, qty, price } = props;

  return (
    <div className='bm-order-item__item'>
      <span className='bm-order-item__item__name'>{ name }</span>
      <div className='bm-order-item__item__info'>
        <span>Qty { qty }</span>
        <span>{ price }</span>
      </div>
    </div>
  )
}

const OrderItem = props => {

  const { order, updateStatus, isUpdatingStatus } = props;

  return (
    <Grid container spacing={3} className='bm-order-item'>
      <Grid item xs={4} className='bm-order-item__patient-details'>
        <Grid container spacing={3}>
          <Grid item>
            <div className='bm-order-item__age-gender-box'>
              <span>{ order.gender }</span>
              <span>{ order.age } y</span>
            </div>
          </Grid>
          <Grid item xs={9} className='bm-order-item__details'>
            <span className='bm-order-item__details__patient-name'>{ order.name }</span>
            <Status 
              status={ order.status }
            />
            <div className='bm-order-item__divider'></div>
            {
              order.items.map(item => (
                <Item 
                  key={item.itemId}
                  name={item.name}
                  qty={item.quantity}
                  price={item.price}
                />
              ))
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <OrderInfo 
          title={'Sub Total:'}
          value={ order.subTotal }
          emptySpace={ <span>&emsp;&emsp;&emsp;&nbsp;&nbsp;</span> }
        />
        <OrderInfo 
          title={'Delivery Charge:'}
          value={ order.deliveryCharge }
          emptySpace={ <span>&nbsp;&nbsp;&nbsp;</span> }
        />
        <OrderInfo 
          title={'Total Amount:'}
          value={ order.total }
          emptySpace={ <span>&emsp;&nbsp;&nbsp;&nbsp;</span> }
        />
      </Grid>
      <Grid item xs={4} className='bm-order-item__action'>
        {
          order.status === 'Confirmed' ? (
            <Button
              variant='contained'
              className='mp-button list-action-button'
              // onClick={ updateStatus }
              startIcon={ isUpdatingStatus ? <CircularProgress size={16} color={'inherit'}  /> : <VideoCall /> }
            >
              View Prescription
            </Button>
          ) : (
            <>
              <Button
                variant='outlined'
                className='mp-button-sm-outline list-action-button vertical-button-group order-list-btn-width'
                onClick={ updateStatus }
                // endIcon={ isUpdatingStatus ? <CircularProgress size={16} color={'inherit'}  /> : <ChevronRight /> }
              >
                View Prescription
              </Button>
              <Button
                variant='contained'
                className='mp-button-sm list-action-button vertical-button-group order-list-btn-width'
                onClick={ updateStatus }
                endIcon={ isUpdatingStatus ? <CircularProgress size={12} color={'inherit'}  /> : <ChevronRight /> }
              >
                Confirm Order
              </Button>
            </>
          )
        }
      </Grid>
    </Grid>
  )
}

export default OrderItem;