import { get } from 'lodash';

import API_INSTANCE from '../../helpers/apiInstance';
import * as constants from '../constants/orders';
import {
  DEFAULT_ERROR_MESSAGE,
  ORDER_CONFIRMED_SUCCESSFULLY,
  APPOINTMENT_CONFIRMED_SUCCESSFULLY
} from '../../constants/messages';
import { 
  formatOrders,
  formatOrdersAfterStatusUpdate,
  formatAppointments,
  formatAppointmentsAfterStatusUpdate
} from '../../helpers/actionHelpers';
import {
  showAlert
} from './alerts';

const dispatchFetchOrders = isFetchingOrders => ({
  type: constants.ACTION_FETCH_ORDERS,
  isFetchingOrders: isFetchingOrders
});

const dispatchOrders = data => ({
  type: constants.ACTION_ORDERS,
  orders: data.orders,
  orderHistory: data.orderHistory,
});

const fetchOrders = () => async (dispatch, getState) => {
  
  dispatch(dispatchFetchOrders(true));
  try {
    const vendorId = get(getState(), ['auth', 'vendorData', 'id'], '');
    const locationId = get(getState(), ['auth', 'selectedLocation'], '');
    const options = {
      url: `${constants.FETCH_ORDERS_URL}?vendor_id=${vendorId}&location_id=${locationId}`,
      method: constants.FETCH_ORDERS_METHOD
    }
    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {

      dispatch(dispatchOrders(formatOrders(get(response, ['data', 0, 'orders'], {}))));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch (error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchFetchOrders(false));
}

const dispatchFetchAppointments = isFetchingAppointments => ({
  type: constants.ACTION_FETCH_APPOINTMENTS,
  isFetchingAppointments: isFetchingAppointments
});

const dispatchAppointments = data => ({
  type: constants.ACTION_APPOINTMENTS,
  appointments: data.appointments,
  appointmentHistory: data.appointmentHistory,
});

const fetchAppointments = () => async (dispatch, getState) => {
  
  dispatch(dispatchFetchAppointments(true));
  try {
    const vendorId = get(getState(), ['auth', 'vendorData', 'id'], '');
    const locationId = get(getState(), ['locations', 'selectedLocation'], '');
    const locations = get(getState(), ['locations', 'locationsList'], []);
    const options = {
      url: `${constants.FETCH_APPOINTMENTS_URL}?vendor_id=${vendorId}&location_id=${locationId}`,
      method: constants.FETCH_APPOINTMENTS_METHOD
    }
    console.log(options.url);
    const response = await API_INSTANCE(options);
    console.log(response);
    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {
      console.log("ddd");
      dispatch(dispatchAppointments(formatAppointments(get(response, ['data', 0, 'orders'], {}), locations)));
    }
    else {
      console.log("dddTTT");
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch (error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchFetchAppointments(false));
}

const dispatchUpdateStatus = isUpdatingStatus => ({
  type: constants.ACTION_UPDATE_STATUS,
  isUpdatingStatus: isUpdatingStatus
});

const updateOrderStatus = (orderId, itemIds, date) => async (dispatch, getState) => {
  
  dispatch(dispatchUpdateStatus(true));
  try {
    const options = {
      url: constants.UPDATE_STATUS_URL,
      method: constants.UPDATE_STATUS_METHOD,
      data: {
        type: 'item',
        id: itemIds,
        status: 'accept'
      }
    }

    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {

      const list = formatOrdersAfterStatusUpdate(get(getState(), ['orders', 'orders'], {}), orderId, 'Confirmed', date);
      dispatch(dispatchOrders({
        orders: list,
        orderHistory: get(getState(), ['orders', 'orderHistory'], [])
      }));
      dispatch(showAlert(ORDER_CONFIRMED_SUCCESSFULLY, 'success'));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch (error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchUpdateStatus(false));
}

const updateAppointmentStatus = (appointmentId, itemId, date) => async (dispatch, getState) => {
  
  dispatch(dispatchUpdateStatus(true));
  try {
    const options = {
      url: constants.UPDATE_STATUS_URL,
      method: constants.UPDATE_STATUS_METHOD,
      data: {
        type: 'item',
        id: itemId,
        status: 'accept'
      }
    }

    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {

      const list = formatAppointmentsAfterStatusUpdate(get(getState(), ['orders', 'appointments'], {}), appointmentId, 'Confirmed', date);
      dispatch(dispatchAppointments({
        appointments: list,
        appointmentHistory: get(getState(), ['orders', 'appointmentHistory'], [])
      }));
      dispatch(showAlert(APPOINTMENT_CONFIRMED_SUCCESSFULLY, 'success'));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch (error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchUpdateStatus(false));
}

export {
  fetchOrders,
  fetchAppointments,
  updateOrderStatus,
  updateAppointmentStatus
}