import { get, cloneDeep } from 'lodash';

import API_INSTANCE from '../../helpers/apiInstance';
import * as constants from '../constants/locations';
import {
  DEFAULT_ERROR_MESSAGE,
  LOGISTICS_UPDATED_SUCCESSFULLY,
  DETAILS_UPDATED_SUCCESSFULLY
} from '../../constants/messages';
import {
  formatLocationLogistics,
  formatWeekDataToUpdateLogistics,
  formatStateList,
  formatLocationAfterUpdate,
  formatLocationLogisticsAfterUpdate
} from '../../helpers/actionHelpers';
import {
  showAlert
} from './alerts';

const dispatchFetchLocations = isFetchingLocations => ({
  type: constants.ACTION_FETCH_LOCATIONS,
  isFetchingLocations: isFetchingLocations
});

const dispatchLocations = locationsList => ({
  type: constants.ACTION_LOCATION_DATA,
  locationsList: locationsList
});

const changeSelectedLocation = selectedLocation => ({
  type: constants.CHANGE_SELECTED_LOCATION,
  selectedLocation: selectedLocation
});

const fetchLocations = () => async (dispatch, getState) => {

  dispatch(dispatchFetchLocations(true));
  try {
    const vendorId = get(getState(), ['auth', 'vendorData', 'id'], '');

    const options = {
      url: `${constants.FETCH_LOCATIONS_URL}/${vendorId}`,
      method: constants.FETCH_LOCATIONS_METHOD
    }
    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {
      dispatch(dispatchLocations(get(response, ['data', 0, 'location'], [])));
      dispatch(changeSelectedLocation(get(response, ['data', 0, 'location', 0, 'location_id'], '')));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch(error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchFetchLocations(false));
}

const dispatchFetchLocationLogistics = isFetchingLocationLogistics => ({
  type: constants.ACTION_FETCH_LOCATION_LOGISTICS,
  isFetchingLocationLogistics: isFetchingLocationLogistics
});

const dispatchLocationLogistics = locationLogistics => ({
  type: constants.ACTION_LOCATION_LOGISTICS_DATA,
  locationLogistics: locationLogistics
});

const fetchLocationLogistics = locationId => async (dispatch, getState) => {
  
  dispatch(dispatchFetchLocationLogistics(true));
  try {
    const vendorId = get(getState(), ['auth', 'vendorData', 'id'], '');
    const URL = cloneDeep(constants.FETCH_LOCATION_LOGISTICS_URL);

    const options = {
      url: `${URL.replace('VENDOR_ID', vendorId).replace('LOCATION_ID', locationId)}`,
      method: constants.FETCH_LOCATIONS_METHOD
    }

    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {
      dispatch(dispatchLocationLogistics(formatLocationLogistics(get(response, ['data', 0, 'location']), [])));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch(error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchFetchLocationLogistics(false));
}

const dispatchUpdateLocationLogistics = isUpdatingLocationLogistics => ({
  type: constants.ACTION_UPDATE_LOCATION_LOGISTICS,
  isUpdatingLocationLogistics: isUpdatingLocationLogistics
});

const updateLocationLogistics = (logistics, weekData) => async (dispatch, getState) => {

  dispatch(dispatchUpdateLocationLogistics(true));

  try {
    
    weekData = formatWeekDataToUpdateLogistics(weekData);
    const options = {
      url: constants.UPDATE_LOCATION_LOGISTICS_URL,
      method: constants.UPDATE_LOCATION_LOGISTICS_METHOD,
      data: {
        logistic_entity_id : logistics.logistic_entity_id,
        destination_country : logistics.destination_country,
        shipping_method : logistics.shipping_method,
        lead_time : logistics.lead_time,
        buffer_days : logistics.buffer_days,
        buffer_days_adjust : logistics.buffer_days_adjust,
        customer_truck_required : logistics.customer_truck_required,
        zip_codes : logistics.zip_codes,
        status : logistics.status,
        cost_channel : logistics.cost_channel,
        priority : logistics.priority,
        ...weekData
      }
    }

    const response = await API_INSTANCE(options);
    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {
      dispatch(dispatchLocationLogistics(formatLocationLogisticsAfterUpdate(get(getState(), ['locations', 'locationLogistics'], []), get(response, ['data', 0, 'logistic']), {})));
      dispatch(showAlert(LOGISTICS_UPDATED_SUCCESSFULLY, 'success'));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch(error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchUpdateLocationLogistics(false));
}

const dispatchFetchStateList = isFetchingStateList => ({
  type: constants.ACTION_FETCH_STATE_LIST,
  isFetchingStateList: isFetchingStateList
});

const dispatchStateList = data => ({
  type: constants.ACTION_STATE_LIST,
  payload: data
});

const fetchStatesList = () => async (dispatch, getState) => {

  dispatch(dispatchFetchStateList(true));
  try {
    
    const options = {
      url: constants.FETCH_STATE_LIST_URL,
      method: constants.FETCH_STATE_LIST_METHOD
    }
    const response = await API_INSTANCE(options);

    if(response.status === 200) {
      dispatch(dispatchStateList({
        statesList: get(response, ['data', 'available_regions'], []),
        stateOptions: formatStateList(get(response, ['data', 'available_regions'], []))
      }));
    }
    else {
      dispatch(showAlert(get(response, ['data', 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch(error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchFetchStateList(false));
}

const dispatchUpdateLocationDetails = isUpdatingLocationDetails => ({
  type: constants.ACTION_UPDATE_LOCATION_DETAILS,
  isUpdatingLocationDetails: isUpdatingLocationDetails
});

const dispatchUpdateLocationData = updatedLocationData => ({
  type: constants.ACTION_UPDATE_LOCATION_DATA,
  updatedLocationData: updatedLocationData
});

const updateLocationDetails = data => async (dispatch, getState) => {

  dispatch(dispatchUpdateLocationDetails(true));
  dispatch(dispatchUpdateLocationData(false));
  try {
    
    const options = {
      url: constants.UPDATE_LOCATION_DETAILS_URL,
      method: constants.UPDATE_LOCATION_DETAILS_METHOD,
      data: {
        location_id: data.locationId,
        loc_name: data.name,
        loc_cust_disp_name: data.displayName,
        loc_phone: data.telephone,
        loc_email: data.email,
        address: {
            street: [
                data.building,
                data.street
            ],
            loc_city: data.city,
            loc_state: data.stateId,
            loc_country: "IN",
            loc_postcode: data.zipcode,
            loc_fax: ""
        },
        status: data.status
      }
    }

    const response = await API_INSTANCE(options);

    if(response.status === 200 && get(response, ['data', 0, 'status']) === true) {
      dispatch(showAlert(DETAILS_UPDATED_SUCCESSFULLY, 'success'));
      dispatch(dispatchLocations(formatLocationAfterUpdate(get(getState(), ['locations', 'locationsList'], []), get(response, ['data', 0, 'location'], {}))));
      dispatch(dispatchUpdateLocationData(true));
    }
    else {
      dispatch(showAlert(get(response, ['data', 0, 'message'], DEFAULT_ERROR_MESSAGE), 'error'));
    }
  }
  catch(error) {
    dispatch(showAlert(DEFAULT_ERROR_MESSAGE, 'error'));
  }
  dispatch(dispatchUpdateLocationDetails(false));
}

export {
  fetchLocations,
  fetchLocationLogistics,
  updateLocationLogistics,
  fetchStatesList,
  updateLocationDetails,
  changeSelectedLocation
}