import React from 'react';
import Alert from '@material-ui/lab/Alert';

const Empty = props => {

  const { message } = props;

  return (
    <section className='empty-alert'>
      <Alert 
        severity='info'
        variant='filled'
        className='empty-alert__alert'
      >
        { message }
      </Alert>
    </section>
  )
}

export default Empty;