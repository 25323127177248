import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import useStyles from '../../hooks/styles/SelectInput';

const SelectInput = props => {

  const { showChooseLabel, required, fullWidth, label, options, hasError, errorMessage, value, handleChange } = props;

  const classes = useStyles();

  const selectOptions = options.map(option => <MenuItem className={classes.root} key={option.id} value={option.id}>{ option.name }</MenuItem>)

  return (
    <FormControl 
      required={required} 
      className={classes.root}
      fullWidth={fullWidth}
      error={hasError}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
      >
        {
          showChooseLabel && (
            <MenuItem value="">
              <em>Choose { label }</em>
            </MenuItem>
          )
        }
        {
          selectOptions
        }
      </Select>
      <FormHelperText>{ errorMessage }</FormHelperText>
    </FormControl>
  );
}

SelectInput.defaultProps = {
  showChooseLabel: true,
  variant: null,
  required: false,
  label: '',
  type: 'text',
  fullWidth: false,
  hasError: false,
  errorMessage: '',
  handleChange: () => {}
}

export default SelectInput;