import React from 'react';
import List from '@material-ui/core/List';
import { useSelector } from 'react-redux';

import DateRangeIcon from '@material-ui/icons/DateRange';
import HistoryIcon from '@material-ui/icons/History';
import LocationOn from '@material-ui/icons/LocationOn';
import PersonOutline from '@material-ui/icons/PersonOutline';
import LocationCity from '@material-ui/icons/LocationCity';
import ExitToApp from '@material-ui/icons/ExitToApp';

import TabIcons from './TabIcons';
import useStyles from '../../hooks/styles/TabIcons';

const Tabs = props => {

  const { activeRoute, handleNavigation } = props;
  const classes = useStyles();
  const login = useSelector(state => state.auth);

  switch (login.selectedCategory) {

    case 'Buy Medicine':
      return (
        <List className={classes.root}>
          <TabIcons 
            Icon={DateRangeIcon}
            text={'Orders'}
            isActive={ activeRoute.endsWith('orders') }
            navigate={ () => handleNavigation('orders') }
          />
          <TabIcons 
            Icon={HistoryIcon}
            text={'Order History'}
            isActive={ activeRoute.endsWith('order-history') }
            navigate={ () => handleNavigation('order-history') }
          />
          <TabIcons 
            Icon={LocationOn}
            text={'Pharmas'}
            isActive={ activeRoute.endsWith('pharmas') }
            navigate={ () => handleNavigation('pharmas') }
          />
          <TabIcons 
            Icon={PersonOutline}
            text={'Profile'}
            isActive={ activeRoute.endsWith('profile') }
            navigate={ () => handleNavigation('profile') }
          />
          <TabIcons 
            Icon={LocationCity}
            text={'Inventory'}
            isActive={ activeRoute.endsWith('inventory') }
            navigate={ () => handleNavigation('inventory') }
          />
          <TabIcons 
            Icon={ExitToApp}
            text={'Logout'}
            isActive={ activeRoute.endsWith('logout') }
            navigate={ () => handleNavigation('logout') }
          />
        </List>
      );
   
    case 'Doctor Appointment':
      return (
        <List className={classes.root}>
          <TabIcons 
            Icon={DateRangeIcon}
            text={'Appointments'}
            isActive={ activeRoute.endsWith('appointments') }
            navigate={ () => handleNavigation('appointments') }
          />
          <TabIcons 
            Icon={HistoryIcon}
            text={'Appointment History'}
            isActive={ activeRoute.endsWith('appointment-history') }
            navigate={ () => handleNavigation('appointment-history') }
          />
          <TabIcons 
            Icon={LocationOn}
            text={'Clinics'}
            isActive={ activeRoute.endsWith('clinics') }
            navigate={ () => handleNavigation('clinics') }
          />
          <TabIcons 
            Icon={PersonOutline}
            text={'Profile'}
            isActive={ activeRoute.endsWith('profile') }
            navigate={ () => handleNavigation('profile') }
          />
          <TabIcons 
            Icon={LocationCity}
            text={'Inventory'}
            isActive={ activeRoute.endsWith('inventory') }
            navigate={ () => handleNavigation('inventory') }
          />
          <TabIcons 
            Icon={ExitToApp}
            text={'Logout'}
            isActive={ activeRoute.endsWith('logout') }
            navigate={ () => handleNavigation('logout') }
          />
        </List>
      );

    case 'Lab Test':
      return (
        <List className={classes.root}>
          <TabIcons 
            Icon={DateRangeIcon}
            text={'Lab Tests'}
            isActive={ activeRoute.endsWith('lab-tests') }
            navigate={ () => handleNavigation('lab-tests') }
          />
          <TabIcons 
            Icon={HistoryIcon}
            text={'Lab Test History'}
            isActive={ activeRoute.endsWith('lab-test-history') }
            navigate={ () => handleNavigation('lab-test-history') }
          />
          <TabIcons 
            Icon={LocationOn}
            text={'Labs'}
            isActive={ activeRoute.endsWith('labs') }
            navigate={ () => handleNavigation('labs') }
          />
          <TabIcons 
            Icon={PersonOutline}
            text={'Profile'}
            isActive={ activeRoute.endsWith('profile') }
            navigate={ () => handleNavigation('profile') }
          />
          <TabIcons 
            Icon={LocationCity}
            text={'Inventory'}
            isActive={ activeRoute.endsWith('inventory') }
            navigate={ () => handleNavigation('inventory') }
          />
          <TabIcons 
            Icon={ExitToApp}
            text={'Logout'}
            isActive={ activeRoute.endsWith('logout') }
            navigate={ () => handleNavigation('logout') }
          />
        </List>
      );
      
    case 'Home Care Services':
      return (
        <List className={classes.root}>
          <TabIcons 
            Icon={DateRangeIcon}
            text={'Services'}
            isActive={ activeRoute.endsWith('home-care-services') }
            navigate={ () => handleNavigation('home-care-services') }
          />
          {/* <TabIcons 
            Icon={HistoryIcon}
            text={'Lab Test History'}
            isActive={ activeRoute.endsWith('lab-test-history') }
            navigate={ () => handleNavigation('lab-test-history') }
          />
          <TabIcons 
            Icon={LocationOn}
            text={'Labs'}
            isActive={ activeRoute.endsWith('labs') }
            navigate={ () => handleNavigation('labs') }
          />
          <TabIcons 
            Icon={PersonOutline}
            text={'Profile'}
            isActive={ activeRoute.endsWith('profile') }
            navigate={ () => handleNavigation('profile') }
          />
          <TabIcons 
            Icon={LocationCity}
            text={'Inventory'}
            isActive={ activeRoute.endsWith('inventory') }
            navigate={ () => handleNavigation('inventory') }
          /> */}
          <TabIcons 
            Icon={ExitToApp}
            text={'Logout'}
            isActive={ activeRoute.endsWith('logout') }
            navigate={ () => handleNavigation('logout') }
          />
        </List>
      );

    default:
      return null;
  }
}

export default Tabs;