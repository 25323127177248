import React from 'react';

import DateDivider from '../common/DateDivider';

const OrderGroups = props => {

  const { date, orders, Component, updateStatus, isUpdatingStatus, updatingOrderId } = props;

  return (
    <>
      <DateDivider 
        date={date}
      />
      {
        orders.map(order => (
          <Component
            key={order.orderId} 
            order={order}
            updateStatus={ () => updateStatus(order.orderId, order.itemIds, date) }
            isUpdatingStatus={ order.orderId === updatingOrderId && isUpdatingStatus }
          />
        ))
      }
    </>
  )
}

export default OrderGroups;