import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import ChevronRight from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from '../../hocs/DefaultLayout';
import TextInput from '../../components/UI/TextInput';

import { inputValidationHelper } from '../../helpers/validations';

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      inputConfig: {
        fullName: {
          required: true,
          fullWidth: true,
          label: 'Full name',
          placeholder: 'Full name',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('fullName', event.target.value)
        },
        email: {
          required: true,
          fullWidth: true,
          keyboardType: 'email-address',
          label: 'Email',
          placeholder: 'Email',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('email', event.target.value)
        },
        telephone: {
          required: true,
          hasPrefix: true,
          keyboardType: 'phone-pad',
          maxLength: 10,
          prefix: '+91',
          fullWidth: true,
          label: 'Phone number',
          placeholder: 'Phone number',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('telephone', event.target.value)
        },
        workExperience: {
          required: true,
          fullWidth: true,
          label: 'Work experience',
          placeholder: 'Work experience',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('workExperience', event.target.value)
        },
        academicInformation: {
          required: true,
          fullWidth: true,
          label: 'Academic information',
          placeholder: 'Academic information',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('academicInformation', event.target.value)
        },
        awardsAndRecognitions: {
          required: true,
          fullWidth: true,
          label: 'Awards and recognitions',
          placeholder: 'Awards and recognitions',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('awardsAndRecognitions', event.target.value)
        },
        hospitalInstututions: {
          required: true,
          fullWidth: true,
          label: 'Hospital / Institutions',
          placeholder: 'Hospital / Institutions',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('hospitalInstututions', event.target.value)
        },
        registrationNumber: {
          required: true,
          fullWidth: true,
          label: 'Registration Number',
          placeholder: 'Registration Number',
          value: '',
          hasError: false,
          errorMessage: false,
          handleChange: (event) => this.handleInputChange('registrationNumber', event.target.value)
        }
      }
    }
  }

  componentDidMount () {
    const { login } = this.props;

    const inputConfig = this.populateInputConfig(login);

    this.setState({
      inputConfig
    });
  }

  populateInputConfig = login => {
    
    const inputConfig = _cloneDeep(this.state.inputConfig);

    inputConfig['fullName']['value'] = _get(login, ['vendorData', 'name'], '');
    inputConfig['email']['value'] = _get(login, ['vendorData', 'email'], '');
    inputConfig['telephone']['value'] = _get(login, ['vendorData', 'telephone'], '');

    return inputConfig;
  }

  handleInputChange = (input, value) => {
    const inputConfig = _cloneDeep(this.state.inputConfig);
    inputConfig[input]['value'] = value;

    this.setState({
      inputConfig
    });
  }

  validateUserInputs = (input, inputConfig, isFormValid) => {
    
    const validationResult = inputValidationHelper(input, input === 'state' ? inputConfig[input]['name'] : inputConfig[input]['value']);
    
    if(!validationResult.isValid)
    {
      inputConfig[input]['hasError'] = true;
      inputConfig[input]['errorMessage'] = validationResult.reason;
      isFormValid = false;
    }
    else {
      inputConfig[input]['hasError'] = false;
      inputConfig[input]['errorMessage'] = ''
    }
    return isFormValid;
  }

  // handleUpdateDetails = () => {

  //   let isFormValid = true;
  //   const inputConfig = _cloneDeep(this.state.inputConfig);
  //   const { locationId } = this.state;
  //   const { updateLocationDetails, locations } = this.props;

  //   if(locations.isUpdatingLocationDetails) return;

  //   isFormValid = this.validateUserInputs('name', inputConfig, isFormValid);
  //   isFormValid = this.validateUserInputs('displayName', inputConfig, isFormValid);
  //   isFormValid = this.validateUserInputs('email', inputConfig, isFormValid);
  //   isFormValid = this.validateUserInputs('telephone', inputConfig, isFormValid);
  //   isFormValid = this.validateUserInputs('building', inputConfig, isFormValid);
  //   isFormValid = this.validateUserInputs('street', inputConfig, isFormValid);
  //   isFormValid = this.validateUserInputs('city', inputConfig, isFormValid);
  //   isFormValid = this.validateUserInputs('state', inputConfig, isFormValid);
  //   isFormValid = this.validateUserInputs('zipcode', inputConfig, isFormValid);

  //   this.setState({
  //     inputConfig
  //   });

  //   if(isFormValid) updateLocationDetails({
  //     locationId: locationId,
  //     name: _get(inputConfig, ['name', 'value'], ''),
  //     displayName: _get(inputConfig, ['displayName', 'value'], ''),
  //     email: _get(inputConfig, ['email', 'value'], ''),
  //     telephone: _get(inputConfig, ['telephone', 'value'], ''),
  //     building: _get(inputConfig, ['building', 'value'], ''),
  //     street: _get(inputConfig, ['street', 'value'], ''),
  //     city: _get(inputConfig, ['city', 'value'], ''),
  //     stateId: _get(inputConfig, ['state', 'value'], ''),
  //     state: _get(inputConfig, ['state', 'name'], ''),
  //     zipcode: _get(inputConfig, ['zipcode', 'value'], ''),
  //     status: _get(inputConfig, ['status', 'value'], '') ? '1' : '0',
  //   });
  // }

  render() {

    const { inputConfig } = this.state;
    const { locations } = this.props;

    return (
      <Layout>
        <div className='profile'>
        <form noValidate autoComplete="off">
          <Grid container spacing={3}>
            <Grid item xs={4} lg={4} xl={4} className='profile__input'>
              <TextInput 
                { ...inputConfig.fullName }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='profile__input'>
              <TextInput 
                { ...inputConfig.email }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='profile__input'>
              <TextInput 
                { ...inputConfig.telephone }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='profile__input'>
              <TextInput 
                { ...inputConfig.workExperience }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='profile__input'>
              <TextInput 
                { ...inputConfig.academicInformation }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='profile__input'>
              <TextInput 
                { ...inputConfig.awardsAndRecognitions }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='profile__input'>
              <TextInput 
                { ...inputConfig.hospitalInstututions }
              />
            </Grid>
            <Grid item xs={4} lg={4} xl={4} className='profile__input'>
              <TextInput 
                { ...inputConfig.registrationNumber }
              />
            </Grid>
            <Grid item xs={6} lg={6} xl={4} className='profile__input profile__input-group'>
              <Button
                variant='contained'
                className='mp-button list-action-button'
                onClick={ this.handleUpdateDetails }
                endIcon={ false ? <CircularProgress size={16} color={'inherit'}  /> : <ChevronRight /> }
              >
                Save Profile
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  login: state.auth
});

const mapDispatchToProps = dispatch => ({
  
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);