import React from 'react';
import Button from '@material-ui/core/Button';

import ChevronRight from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';

import Status from '../UI/Status';

const OrderInfo = props => {

  const { title, value } = props;

  return (
    <div className='lt-order-item__info'>
      <span className='lt-order-item__info__title'>{ title }</span>
      <span className='lt-order-item__info__value'>{ value }</span>
    </div>
  )
}

const LabTestItem = props => {

  const { order, updateStatus, isUpdatingStatus } = props;

  return (
    <div className='lt-order-item'>
      <div className='lt-order-item__patient-details'>
        <div className='lt-order-item__age-gender-box'>
          <span>{ order.gender }</span>
          <span>{ order.age } y</span>
        </div>
        <div className='lt-order-item__details'>
          <span className='lt-order-item__details__patient-name'>{ order.name }</span>
          <Status 
            status={ order.status }
          />
          <OrderInfo 
            title={'Request ID:'}
            value={ order.appointmentId }
          />
          <OrderInfo 
            title={'Slot:'}
            value={ order.appointmentTime }
          />
          <OrderInfo 
            title={'Reason:'}
            value={ order.reasonForVisit }
          />
        </div>
      </div>
      <div className='lt-order-item__action'>
        {
          order.status === 'Confirmed' ? null : (
            <Button
              variant='contained'
              className='mp-button-sm list-action-button'
              onClick={ updateStatus }
              endIcon={ isUpdatingStatus ? <CircularProgress size={16} color={'inherit'}  /> : <ChevronRight /> }
            >
              Confirm
            </Button>
          )
        }
      </div>
    </div>
  )
}

export default LabTestItem;