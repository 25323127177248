import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';

import SelectInput from '../UI/SelectInput';
import TextInput from '../UI/TextInput';

import { changeSelectedLocation } from '../../store/actions/locations';

const InventoryFilters = props => {

  const dispatch = useDispatch();
  const locations = useSelector(state => state.locations);
  const { searchText, handleChange } = props;

  const locationOptions = locations.locationsList.map(location => ({ id: location.location_id, name: location.loc_name }))
  
  const locationInput = _isEmpty(locationOptions) ? null : (
    <Grid item xs={2}>
      <SelectInput 
        fullWidth={true}
        label={'Locations'}
        showChooseLabel={false}
        value={locations.selectedLocation}
        options={locationOptions}
        handleChange={ (event) => dispatch(changeSelectedLocation(event.target.value)) }
      />
    </Grid>
  );

  const searchInput = _isEmpty(locationOptions) ? null : (
    <Grid item xs={2}>
      <TextInput 
        label={'Search'}
        fullWidth={true}
        value={ searchText }
        handleChange={ (event) => handleChange(event.target.value) }
      />
    </Grid>
  );

  return (
    <Grid container spacing={5} className='order-filter-container'>
      <Grid item xs={8}>

      </Grid>
      {
        searchInput
      }
      {
        locationInput
      }
    </Grid>
  );
}

export default InventoryFilters;