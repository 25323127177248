import { makeStyles } from '@material-ui/core/styles';

import {
  PRIMARY,
  WHITE
} from '../../assets/colors/colors';

const useStyles = makeStyles({
  root: {
    '& .MuiTabs-root': {
      backgroundColor: PRIMARY
    },
    '& button.MuiTab-root': {
      fontSize: '16px',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: WHITE
    }
  }
});

export default useStyles;