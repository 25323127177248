import { get, cloneDeep, clone, isEmpty } from 'lodash';
import moment from 'moment';

const formatVendorData = data => {
  return {
    id: get(data, 'vendor_id', ''),
    name: get(data, 'vendor_name', ''),
    telephone: get(data, 'telephone', ''),
    email: get(data, 'email', '')
  }
}

const formatEnabledCategories = data => {
  const enabledCategories = {};
  data.forEach(currentData => enabledCategories[currentData.vendor_type_label] = currentData.vendor_type_id);
  return enabledCategories;
}

const formatLocationLogistics = logistics => {

  return logistics.map(data => {
    const formatedWeekdays = formatWeekData(data);
    data['weekdays'] = formatedWeekdays;
    return data;
  });
}

const formatLocationLogisticsAfterUpdate = (list, logistics) => {

  return list.map(data => {
    
    if(data.shipping_method !== logistics.shipping_method) return data;
    const formatedWeekdays = formatWeekData(logistics);
    data['weekdays'] = formatedWeekdays;
    return data;
  });
}

const formatWeekData = data => {
  
  const weekdays = [
    {
      day: 'Monday',
      apiDay: 'monday',
      openTimings: '',
      closeTimings: '',
      isValid: true,
      availability: false
    },
    {
      day: 'Tuesday',
      apiDay: 'tuesday',
      openTimings: '',
      closeTimings: '',
      isValid: true,
      availability: false
    },
    {
      day: 'Wednesday',
      apiDay: 'wednesday',
      openTimings: '',
      closeTimings: '',
      isValid: true,
      availability: false
    },
    {
      day: 'Thursday',
      apiDay: 'thursday',
      openTimings: '',
      closeTimings: '',
      isValid: true,
      availability: false
    },
    {
      day: 'Friday',
      apiDay: 'friday',
      openTimings: '',
      closeTimings: '',
      isValid: true,
      availability: false
    },
    {
      day: 'Saturday',
      apiDay: 'saturday',
      openTimings: '',
      closeTimings: '',
      isValid: true,
      availability: false
    },
    {
      day: 'Sunday',
      apiDay: 'sunday',
      openTimings: '',
      closeTimings: '',
      isValid: true,
      availability: false
    }
  ];

  return weekdays.map(weekday => {
    weekday = cloneDeep(weekday);

    let weekDayData = get(data, weekday.apiDay, '');
    weekDayData = weekDayData.split(',');

    let timingsSplit = get(weekDayData, 0, '').split('-');

    let openTimings = get(timingsSplit, 0, '');
    let closeTimings = get(timingsSplit, 1, '');

    weekday.openTimings = openTimings;
    weekday.closeTimings = closeTimings;
    weekday.isValid = moment(openTimings, 'HH:mm').diff(moment(closeTimings, 'HH:mm')) <= 0;
    weekday.availability = get(weekDayData, 1, '') === "1" ? true : false;
    return weekday;
  });
}

const formatWeekDataToUpdateLogistics = weekData => {
  const apiWeekData = {};
  weekData.forEach(data => {
    const availability = data.availability ? 1 : 0;
    let timings = `${data.openTimings}-${data.closeTimings}`;
    apiWeekData[data.apiDay] = `${timings},${availability}`;
  });
  return apiWeekData;
}

const formatStateList = statesList => {
  return statesList.map(state => ({ value: state.id, display: state.name }));
}

const formatLocationAfterUpdate = (list, location) => {
  return list.map(currentLocation => currentLocation.location_id === location.location_id ? location : currentLocation);
}

const formatEarnings = earnings => {
  
  const keyMapping = {
    today: 'Today',
    last_week: 'Last Week',
    last_month: 'Last Month',
    last_year: 'Last Year',
  };
  const formattedEarnings = {};
  let total = 0;
  for(const earning in earnings) {
    formattedEarnings[keyMapping[earning]] = earnings[earning];
    total += get(earnings, [earning, 'revenue'], 0);
  }
  return { earnings: formattedEarnings, totalEarnings: total };
}

const formatInventoryAfterUpdate = (list, inventory) => {

  const index = list.findIndex(currentInventory => currentInventory.offer_id === inventory.offer_id);
  list = cloneDeep(list);
  if(index >= 0) {
    list[index]['qty_confirmed'] = inventory.qty_confirmed;
    list[index]['eod_inventory'] = inventory.eod_inventory;
    list[index]['qty_uploaded'] = inventory.qty_uploaded;
    list[index]['inventory_qty'] = inventory.inventory_qty;
  }
  return list;
}

const formatOrders = data => {

  const orderHistory = {};
  const orders = {};

  for(const orderId in data) {
    const order = data[orderId];
    const items = [];
    const itemIds = [];
    let deliveryDate = '';

    order.forEach(orderItem => {

      deliveryDate = moment(get(orderItem, ['items', 'pickup_date'], '')).format('DD MMM, YYYY');

      items.push({
        itemId: get(orderItem, ['items', 'item_id'], ''),
        name: get(orderItem, ['items', 'name'], ''),
        productId: get(orderItem, ['items', 'product_id'], ''),
        quantity: parseInt(get(orderItem, ['items', 'qty_ordered'], 0)),
        price: Number(get(orderItem, ['items', 'price'], 0)).toFixed(2),
      });
      itemIds.push(get(orderItem, ['items', 'item_id'], ''));
    });

    if(!orders[deliveryDate]) orders[deliveryDate] = [];
    if(!orderHistory[deliveryDate]) orderHistory[deliveryDate] = [];

    orderHistory[deliveryDate].push({
      orderId: get(order, [0, 'order', 'order_number'], ''),
      name: get(order, [0, 'shipping_address', 'firstname'], ''), 
      age: get(order, [0, 'items', 'age'], '') ? get(order, [0, 'items', 'age'], '') : '-', 
      gender: get(order, [0, 'items', 'sex'], '') ? get(order, [0, 'items', 'sex'], '') : '-', 
      status: 'Pending',
      orderTotal: Number(get(order, [0, 'order', 'grand_total'], 0)).toFixed(2), 
    });
    
    orders[deliveryDate].push({
      orderId: get(order, [0, 'order', 'order_number'], ''),
      name: get(order, [0, 'shipping_address', 'firstname'], ''), 
      age: get(order, [0, 'items', 'age'], '') ? get(order, [0, 'items', 'age'], '') : '-', 
      gender: get(order, [0, 'items', 'sex'], '') ? get(order, [0, 'items', 'sex'], '') : '-', 
      subTotal: Number(get(order, [0, 'order', 'subtotal'], 0)).toFixed(2), 
      deliveryCharge: Number(get(order, [0, 'order', 'shipping_amount'], 0)).toFixed(2), 
      total: Number(get(order, [0, 'order', 'grand_total'], 0)).toFixed(2),
      items: items,
      itemIds: itemIds.join(','),

      status: 'Pending'
    });
  }

  return { orders: { CONFIRMED: {}, PENDING: orders }, orderHistory: orderHistory };
}

const formatOrdersAfterStatusUpdate = (list, orderId, status, date) => {

  list = cloneDeep(list);
  const pendingIndex = get(list, ['PENDING', date], []).findIndex(order => order.orderId === orderId);

  if(pendingIndex >= 0) {
    
    const order = list['PENDING'][date][pendingIndex];
    order.status = status;

    list['PENDING'][date].splice(pendingIndex, 1);
    if(isEmpty(list['PENDING'][date])) delete list['PENDING'][date];

    if(!list['CONFIRMED'][date]) list['CONFIRMED'][date] = [];
    list['CONFIRMED'][date].unshift(order);
  }
  return list;
};

const formatAppointments = (data, locations) => {

  const appointmentHistory = [];
  const formattedAppointments = {};
  const locationMapping = {};
  locations.forEach(location => locationMapping[location.location_id] = location.loc_name);

  for(const appointmentId in data) {
    const appointment = data[appointmentId];

    appointment.forEach(appointmentItem => {

      const appointmentDate = moment(get(appointmentItem, ['items', 'pickup_date'], '')).format('DD MMM, YYYY');
      const details = {
        appointmentId: `${get(appointmentItem, ['order', 'order_number'], '')}_${get(appointmentItem, ['items', 'item_id'], '')}`,
        itemId: get(appointmentItem, ['items', 'item_id'], ''),
        name: get(appointmentItem, ['items', 'patientName'], 'Patient Name'), 
        age: get(appointmentItem, ['items', 'age'], '') ? get(appointmentItem, ['items', 'age'], '') : '-', 
        gender: get(appointmentItem, ['items', 'sex'], '') ? get(appointmentItem, ['items', 'sex'], '') : '-', 
        orderTotal: Number(get(appointmentItem, ['items', 'price'], 0)).toFixed(2),
        appointmentDate: appointmentDate,
        appointmentTime: get(appointmentItem, ['items', 'time_slot_from'], ''),
        consultationType: get(appointmentItem, ['items', 'consultation_type'], ''),
        reasonForVisit: get(appointmentItem, ['items', 'name'], ''),
        locationName: locationMapping[get(appointmentItem, ['items', 'vendor_farm'], '')],

        status: 'Pending'
      };

      appointmentHistory.push(details);

      console.log(appointmentHistory);

      if(!formattedAppointments[appointmentDate]) formattedAppointments[appointmentDate] = [];
      formattedAppointments[appointmentDate].push(details);
    });
  }
  return { appointments: { CONFIRMED: {}, PENDING: formattedAppointments }, appointmentHistory: formattedAppointments };
}

const formatAppointmentsAfterStatusUpdate = (list, appointmentId, status, date) => {

  list = cloneDeep(list);

  const pendingIndex = get(list, ['PENDING', date], []).findIndex(appointment => appointment.appointmentId === appointmentId);

  if(pendingIndex >= 0) {
    
    const appointment = list['PENDING'][date][pendingIndex];
    appointment.status = status;
    
    list['PENDING'][date].splice(pendingIndex, 1);
    if(isEmpty(list['PENDING'][date])) delete list['PENDING'][date];

    if(!list['CONFIRMED'][date]) list['CONFIRMED'][date] = [];
    list['CONFIRMED'][date].unshift(appointment);
  }

  return list;
};

export {
  formatVendorData,
  formatEnabledCategories,
  formatLocationLogistics,
  formatWeekDataToUpdateLogistics,
  formatLocationLogisticsAfterUpdate,
  formatStateList,
  formatLocationAfterUpdate,
  formatEarnings,
  formatInventoryAfterUpdate,
  formatOrders,
  formatOrdersAfterStatusUpdate,
  formatAppointments,
  formatAppointmentsAfterStatusUpdate
}