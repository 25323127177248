import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import useStyles from '../../hooks/styles/TabIcons';
import {
  TERTIARY,
  PRIMARY,
  NOT_SO_BLACK
} from '../../assets/colors/colors';

const TabIcons = props => {

  const { text, Icon, navigate, isActive } = props;
  const classes = useStyles();
  const className = isActive ? classes.active : classes.root;
  const style = { fontSize: 25, color: isActive ? PRIMARY : NOT_SO_BLACK };

  return (
    <ListItem button key={text} onClick={navigate}>
      <ListItemIcon>
        <Icon 
          style={style}
          className={className}
        />
      </ListItemIcon>
      <ListItemText primary={text} className={className} />
    </ListItem>
  );
}

export default TabIcons;