import React from 'react';

import Layout from '../../hocs/DefaultLayout';
import Empty from '../../components/UI/Empty';


class Services extends React.Component {

  render() {

    return (
      <Layout>
        <Empty 
          message={ 'Under Development' }
        />
      </Layout>
    )
  }
}

export default Services;