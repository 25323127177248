import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import SwitchInput from '../../components/UI/SwitchInput';
import TimePicker from '../../components/UI/TimePicker';

import { updateLocationLogistics } from '../../store/actions/locations';

class AvailabilityItem extends React.Component {

  constructor(props) {
    super(props);
    this.state={
      weekdays: [],
      shippingMethod: '',
      isFormValid: true
    }
  }

  componentDidMount() {
    this.manageState();
  }

  componentDidUpdate(prevProps) {
    if(!_isEqual(prevProps.logistic, this.props.logistic)) this.manageState();
  }

  manageState = () => {

    const { logistic } = this.props;
    this.setState({
      weekdays: _cloneDeep(_get(logistic, 'weekdays', [])),
      shippingMethod: _get(logistic, 'shipping_method', '')
    });
  }

  handleAvailability = index => {
    const weekdays = _cloneDeep(this.state.weekdays);
    const item = weekdays[index];
    item.availability = !item.availability;
    weekdays[index] = item;

    this.setState({
      weekdays
    });
  }

  handleTimings = (index, openTimings, closeTimings) => {

    const { isFormValid } = this.state;
    const weekdays = _cloneDeep(this.state.weekdays);
    const item = weekdays[index];
    
    item.openTimings = openTimings;
    item.closeTimings = closeTimings;
    item.isValid = moment(openTimings, 'HH:mm').diff(moment(closeTimings, 'HH:mm')) <= 0;
    weekdays[index] = item;

    this.setState({
      weekdays,
      isFormValid: isFormValid && item.isValid
    });
  }

  resetDetails = () => this.manageState();

  handleLogisticsUpdate = () => {
    
    const { updateLocationLogistics, logistic, locations } = this.props;
    const { weekdays } = this.state;

    if(locations.isUpdatingLocationLogistics) return;

    updateLocationLogistics(logistic, _cloneDeep(weekdays));
  }

  render() {

    const { shippingMethod, weekdays, isFormValid } = this.state;
    const { active, close, handleActiveLogistic } = this.props;

    return (
      <div className='availability-item'>
        <Accordion
          expanded={active}
          onChange={ () => {
            this.resetDetails();
            handleActiveLogistic()
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon 
                fontSize='large' 
                onClick={ () => { 
                  this.resetDetails();
                  close();
                }}
              />
            }
          >
            <span className='availability-item__name'>Shipping method {shippingMethod}</span>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {
                weekdays.map((day, index) => (
                  <WeekDay 
                    key={index}
                    openTimings={day.openTimings}
                    closeTimings={day.closeTimings}
                    isAvailable={day.availability}
                    isValid={day.isValid}
                    day={day.day}
                    handleTimings={ this.handleTimings.bind(null, index) }
                    handleAvailability={ () => this.handleAvailability(index) }
                  />
                ))
              }
            </Grid>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button 
              className='availability-item-btn'
              onClick={ this.resetDetails }
            >
              <span>Cancel</span>
            </Button>
            <Button 
              disabled={!isFormValid}
              className='availability-item-btn availability-item-btn-active'
              onClick={ this.handleLogisticsUpdate }
            >
              <span>{ this.props.locations.isUpdatingLocationLogistics ? 'Saving...' : 'Save' }</span>
            </Button>
          </AccordionActions>
        </Accordion>
      </div>
    )
  }
}

const WeekDay = props => {

  const { openTimings, closeTimings, isValid, isAvailable, day, handleAvailability, handleTimings } = props;
  
  return (
    <Grid item>
      <div className='weekday-card'>
        <div className='weekday-card__header'>
          <span>{ day }</span>
        </div>
        <div className='weekday-card__body'>
          <TimePicker 
            value={openTimings}
            handleChange={ (event) => handleTimings(event.target.value, closeTimings) }
            hasError={!isValid}
          />
          <span className='weekday-card__body__divider'> to </span>
          <TimePicker 
            value={closeTimings}
            handleChange={ (event) => handleTimings(openTimings, event.target.value) }
            hasError={!isValid}
          />
        </div>
        <div className='weekday-card__footer'>
          <span>is Available?</span>
          <SwitchInput 
            size={'small'}
            label={''}
            value={isAvailable}
            handleChange={ handleAvailability }
          />
        </div>
      </div>
    </Grid>
  );
}

const mapStateToProps = state => ({
  locations: state.locations
});

const mapDispatchToProps = dispatch => ({
  updateLocationLogistics: (logistics, weekData) => dispatch(updateLocationLogistics(logistics, weekData))
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityItem);