import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

import alertsReducer from './reducers/alerts';
import authReducer from './reducers/auth';
import earningsReducer from './reducers/earnings';
import inventoryReducer from './reducers/inventory';
import locationsReducer from './reducers/locations';
import ordersReducer from './reducers/orders';
import uiReducer from './reducers/ui';

const rootReducer = combineReducers({
  alerts: alertsReducer,
  auth: authReducer,
  earnings: earningsReducer,
  inventory: inventoryReducer,
  locations: locationsReducer,
  orders: ordersReducer,
  ui: uiReducer
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = composeWithDevTools({
  // Specify here name, actionsBlacklist, actionsCreators and other options
});

const configureStore = () => {
  return createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
};

export default configureStore;