export const FETCH_LOCATIONS_URL = 'vendor/locations-list';
export const FETCH_LOCATIONS_METHOD = 'GET';
export const ACTION_FETCH_LOCATIONS = 'ACTION_FETCH_LOCATIONS';
export const ACTION_LOCATION_DATA = 'ACTION_LOCATION_DATA';

export const CHANGE_SELECTED_LOCATION = 'CHANGE_SELECTED_LOCATION';

export const FETCH_LOCATION_LOGISTICS_URL = 'vendor/VENDOR_ID/logistics-list/LOCATION_ID';
export const FETCH_LOCATION_LOGISTICS_METHOD = 'GET';
export const ACTION_FETCH_LOCATION_LOGISTICS = 'ACTION_FETCH_LOCATION_LOGISTICS';
export const ACTION_LOCATION_LOGISTICS_DATA = 'ACTION_LOCATION_LOGISTICS_DATA';

export const UPDATE_LOCATION_LOGISTICS_URL = 'vendor/logistic';
export const UPDATE_LOCATION_LOGISTICS_METHOD = 'PUT';
export const ACTION_UPDATE_LOCATION_LOGISTICS = 'ACTION_UPDATE_LOCATION_LOGISTICS';

export const FETCH_STATE_LIST_URL = 'directory/countries/IN';
export const FETCH_STATE_LIST_METHOD = 'GET';
export const ACTION_FETCH_STATE_LIST = 'ACTION_FETCH_STATE_LIST';
export const ACTION_STATE_LIST = 'ACTION_STATE_LIST';

export const UPDATE_LOCATION_DETAILS_URL = 'vendor/location';
export const UPDATE_LOCATION_DETAILS_METHOD = 'PUT';
export const ACTION_UPDATE_LOCATION_DETAILS = 'ACTION_UPDATE_LOCATION_DETAILS';
export const ACTION_UPDATE_LOCATION_DATA = 'ACTION_UPDATE_LOCATION_DATA';