import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import MoreVert from '@material-ui/icons/MoreVert';

import { changeSelectedCategory } from '../../store/actions/auth';

const VendorTypes = props => {

  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const dispatch = useDispatch();
  const vendorTypes = useSelector(state => state.auth.enabledCategories);
  const selectedVendorType = useSelector(state => state.auth.selectedCategory);

  const menuItems = [];
  for(let vendorType in vendorTypes) {
    menuItems.push((
      <MenuItem 
        className={`vendor-types__menu-item ${selectedVendorType === vendorType ? 'vendor-types__menu-item-active' : ''}`} 
        key={vendorType} 
        onClick={() => dispatch(changeSelectedCategory(vendorType))}
      >
        { vendorType }
      </MenuItem>)
    )
  }

  return (
    <div className='vendor-types'>
      <IconButton
        color='inherit'
        aria-label='vendor types'
        onClick={() => setMenuOpen(true)}
      >
        <MoreVert fontSize='large' />
      </IconButton>
      <Popper 
        open={isMenuOpen} 
        transition 
        disablePortal 
        className='vendor-types__menu'
      >
        <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
          <MenuList
            // onClose={handleVendorType}
          >
            {
              menuItems
            }
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </div>
  )

}

export default VendorTypes;